/* eslint-disable react/prop-types */
import React from 'react';

export default function NavUpBtn({
  cart,
  purchaseStep,
}) {
  return (
    <button
      type="button"
      className={`fixed ${(cart?.grandTotal > 0 && purchaseStep === 0) ? 'hidden' : 'right-7'} bottom-5 lg:right-7 cursor-pointer bg-sky-400 text-white rounded-full py-3 px-4 z-50`}
      aria-label="up"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <i className="fas fa-angle-up" />
    </button>
  );
}
