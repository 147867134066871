/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CartDetails from './cartDetails';

export default function FixedCartBtn({
  cart,
  setPurchaseStep,
  items,
}) {
  const [animate, setAnimate] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showLittleFixedCart, setShowLittleFixedCart] = useState(false);
  const getTotalUsersItems = () => {
    let sum = 0;
    if (cart?.isAGift) {
      return 1;
    }
    cart?.tandemOrderItems.forEach(() => { sum += 1; });
    return sum;
  };
  useEffect(() => {
    if (cart?.isAGift || cart?.tandemOrderItems?.length === 1) {
      setShowLittleFixedCart(true);
    }
    setAnimate(true);
  }, [cart]);
  return (
    <>
      <button
        type="button"
        className={`fixed cursor-pointer rounded-full py-3 px-4 z-50 lg:hidden
        ${showLittleFixedCart
          ? 'hidden'
          : 'bg-sky-400 text-white bottom-5 right-7'}
          ${animate && 'animate-shake'}`}
        aria-label="freecart"
        onClick={() => setShowLittleFixedCart(!showLittleFixedCart)}
        onAnimationEnd={() => setAnimate(false)}
      >
        <i className="fas fa-cart-shopping mr-1" />
        {getTotalUsersItems()}
      </button>
      <div className={`fixed bottom-5 right-7 bg-white shadow-lg text-zinc-600 rounded-sm p-3 z-40 w-[280px] lg:w-[330px] mt-6 text-xs lg:hidden
        ${(showLittleFixedCart)
        ? ' block'
        : ' hidden'}`}
      >
        <div className="flex justify-between">
          <h3 className="text-xs m-1 text-left">
            Mon pannier
          </h3>
          <button
            type="button"
            className="rounded-full bg-sky-400 py-1 px-2 lg:px-3"
            aria-label="close"
            onClick={() => setShowLittleFixedCart(false)}
          >
            <i className="fas fa-xmark text-sm lg:text-lg text-white" />
          </button>
        </div>
        {(cart?.tandemOrderItems?.length === 0 || cart === undefined)
          && (
            <div className="m-3">
              <p>
                Aucune réservations
              </p>
            </div>
          )}
        {(cart?.isAGift)
          && (
            <div className="m-3">
              <p>
                {`Un bon cadeau (${cart.grandTotal / 100}€)`}
              </p>
            </div>
          )}
        <ul>
          {cart?.tandemOrderItems?.length > 0
            && (
              cart?.tandemOrderItems?.map(
                (x) => (
                  <CartDetails
                    key={x.id}
                    tandemOrderItem={x}
                    items={items}
                  />
                ),
              )
            )}
        </ul>
        {!cart.isAGift
          && (
            <button
              type="button"
              className="AddictSkyButton text-xs w-full mt-6 uppercase"
              onClick={() => {
                if (location?.pathname !== '/reserver/bapteme') {
                  navigate('/reserver/bapteme');
                  // return;
                }
                setShowLittleFixedCart(false);
                setPurchaseStep(1);
              }}
            >
              reserver
            </button>
          )}
      </div>
    </>
  );
}
