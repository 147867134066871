/* eslint-disable react/prop-types */
import React from 'react';

export default function ImagesAfterTandems() {
  return (
    <>
      <div className="absolute top-0 left-3 w-1/2 h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="volez en parapente (tandem) 1"
          className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
          src="/image/homeImages/passenger_1_small.webp"
          loading="lazy"
        />
      </div>
      <div className="absolute top-[80px] right-3 w-1/2 h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="volez et observez un baleine"
          className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
          src="/image/homeImages/whales_reunion_island_small.webp"
          loading="lazy"
        />
      </div>
      <div className="absolute top-[180px] left-4 w-[calc(50%+20px)] h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="volez en parapente (tandem) 2"
          className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
          src="/image/homeImages/passenger_2_small.webp"
          loading="lazy"
        />
      </div>
      <div className="absolute top-[260px] right-3 w-1/2 h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="voler en parapente (tandem) 3"
          className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
          src="/image/homeImages/passenger_3_small.webp"
          loading="lazy"
        />
      </div>
    </>
  );
}
