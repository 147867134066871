import { useState, useEffect } from 'react';

const baseURL = process.env.REACT_APP_API_URL;

export default function useDatav2(url, param) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (url) {
      setIsLoading(true);
      // eslint-disable-next-line no-param-reassign
      url = param ? url + param : url;
      let ignore = false;
      fetch(
        baseURL + url,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => response.json())
        .then((json) => {
          if (!ignore) {
            setData(json);
            setIsLoading(false);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [url, param]);
  return { data, isLoading, setData };
}
