/* eslint-disable react/prop-types */
import React from 'react';

export default function PartnersSection() {
  return (
    <div className="flex flex-col justify-center items-center text-center bg-zinc-700 w-full h-full">
      <h3 className="text-white text-3xl font-playfair w-full mt-12">
        Partenaires officiels
      </h3>
      <div className="flex flex-wrap justify-center items-center text-white w-full m-12 gap-4">
        <a
          href="https://odb.re/odb-sport/"
          className="w-32 lg:w-44 h-auto lg:h-auto bg-transparent rounded-xl p-2"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="odb"
            src="image/partners/normal/logo-odb.svg"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.leffet-boutik.re/"
          className="w-32 lg:w-44 h-auto lg:h-auto bg-transparent rounded-xl p-2"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="effet pei"
            src="image/partners/normal/logo-effetpei.svg"
            loading="lazy"
          />
        </a>
        <a
          href="https://nrj.re/"
          className="w-32 lg:w-44 h-auto lg:h-auto bg-transparent rounded-xl"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="nrj"
            src="image/partners/normal/logo-nrj.svg"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.facebook.com/tezitea/"
          className="w-32 lg:w-44 h-auto lg:h-auto bg-transparent rounded-xl"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="tezi tea"
            src="image/partners/normal/logo-tezi.svg"
            loading="lazy"
          />
        </a>
      </div>
    </div>
  );
}
