/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import Payment from '../stripe/payment';
import PassengerSelectDate from './PassengerSelectDate';
import Contacts from './Contacts';
import Policy from './Policy';

export default function Order({
  cart,
  setCart,
  dates,
  setDates,
  purchaseStep,
  setPurchaseStep,
  stripePromise,
  setError,
  items,
  eventsDetails,
  setEventsDetails,
}) {
  const passengerRefs = useRef({});
  const paymentRef = useRef();
  const headRef = useRef();
  const mailFormat = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const nameFormat = /^[a-zA-ZÀ-ú ._-]{3,20}$/;
  const [orderIdx, setOrderIdx] = useState(0);
  const [localError, setLocalError] = useState('');
  const [showPayment, setShowPayment] = useState(false);
  const [month, setMonth] = useState(new Date());

  useEffect(() => {
    if (headRef && orderIdx <= cart.tandemOrderItems.length) {
      headRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [purchaseStep, orderIdx]);
  const checkCart = () => {
    let idx = 0;
    const isCartOrderItemValid = cart.tandemOrderItems.some((x) => {
      let missingOrderItemField = '';
      if (missingOrderItemField === '' && (!x?.age || (x?.age < 5 || x?.age > 80))) missingOrderItemField = 'Renseignez un age valide';
      if (missingOrderItemField === '' && (!x?.weight || (x?.weight < 10 || x?.weight > 90))) missingOrderItemField = 'Renseignez un poids valide';
      if (missingOrderItemField === '' && !x?.eventID) missingOrderItemField = 'Séléctionnez une date';
      if (missingOrderItemField === '' && !x?.firstName) missingOrderItemField = 'Renseignez un prénom';
      if (missingOrderItemField === '' && x?.firstName.match(nameFormat) === null) missingOrderItemField = 'Renseignez un prénom valide';
      if (missingOrderItemField === '' && !x?.lastName) missingOrderItemField = 'Renseignez un nom';
      if (missingOrderItemField === '' && x?.lastName.match(nameFormat) === null) missingOrderItemField = 'Renseignez un nom valide';
      if (missingOrderItemField === '' && !x?.isSaved) missingOrderItemField = 'Confirmez ce vol';
      if (missingOrderItemField === '' && x.contactPassenger && !x?.phone) missingOrderItemField = 'Renseignez un numéro de téléphone';
      if (missingOrderItemField === '' && x.contactPassenger && x?.phone
        && (!isValidPhoneNumber(x?.phone)
        || !isPossiblePhoneNumber(x?.phone))) missingOrderItemField = 'Renseignez un numéro de téléphone valide';
      if (missingOrderItemField === '' && x.contactPassenger && !x?.email) missingOrderItemField = 'Renseignez un e-mail';
      if (missingOrderItemField === '' && x.contactPassenger && x?.email
        && (!x?.email
        || x?.email.match(mailFormat) === null)) missingOrderItemField = 'Renseignez un e-mail valide';
      if (missingOrderItemField === '' && !x.isPassengerAbleToRun) missingOrderItemField = 'Validez nos conditions';
      if (missingOrderItemField !== '') {
        const itemDetails = items?.find((j) => j.id === x.itemID);
        setLocalError(`${missingOrderItemField} pour le vol ${idx + 1}/${cart.tandemOrderItems.length} ${itemDetails?.name}`);
        passengerRefs.current[idx].current.scrollIntoView({ behavior: 'smooth' });
        return true;
      }
      idx += 1;
      return false;
    });
    if (isCartOrderItemValid) {
      return;
    }

    let missingcartField = '';
    if (missingcartField === '' && !cart?.phone) missingcartField = 'Renseignez un numéro de téléphone';
    if (missingcartField === '' && cart?.phone
      && (!isValidPhoneNumber(cart?.phone)
      || !isPossiblePhoneNumber(cart?.phone))) missingcartField = 'Renseignez un numéro de téléphone valide';
    if (missingcartField === '' && !cart?.email) missingcartField = 'Renseignez un e-mail';
    if (missingcartField === '' && (!cart?.email
      || cart?.email.match(mailFormat) === null)) missingcartField = 'Renseignez un e-mail valide';
    if (missingcartField === '' && !cart?.isCGUAccepted) missingcartField = 'Acceptez les conditions d\'utilisation';
    if (missingcartField === '' && !cart?.isCGVAccepted) missingcartField = 'Acceptez les conditions de vente';
    if (missingcartField !== '') {
      setLocalError(`${missingcartField} `);
      paymentRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (!isCartOrderItemValid) {
      setShowPayment(true);
    }
  };
  return (
    <div ref={headRef} className="flex flex-col justify-between w-full h-full text-gray-500 mb-16">
      <div className="flex flex-col justify-between items-center w-full text-xs lg:text-sm">
        {/* Head Image */}
        <div className="w-full h-44 lg:h-80 shadow-lg">
          <img
            alt="payment"
            className="object-cover w-full h-full"
            src="/image/paragliding/training/formation-addictparapente-n4-2.webp"
          />
        </div>
        {/* Button to return into select items */}
        <div className="pt-10 lg:pt-10 w-full lg:w-1/2 px-3 lg:px-0 flex justify-start mb-6">
          <button
            className="w-2/3 lg:w-72 bg-white hover:bg-sky-400 text-sky-400 hover:text-white rounded-lg px-3 py-2 font-bold text-center shadow hover:shadow-lg text-xs"
            type="button"
            onClick={() => { setPurchaseStep(0); }}
          >
            <i className="fas fa-arrow-left mr-1" />
            Retour à la séléction des vols
          </button>
        </div>
        {localError !== ''
          && (
            <div className="animate-shake fixed flex flex-col justify-center items-center gap-2 top-1/2 -translate-y-1/2 w-96 bg-sky-400 text-white shadow-xl rounded-lg px-6 py-3 z-50 text-center">
              <p className="font-bold text-sm">
                {localError}
              </p>
              <button
                type="button"
                className="bg-white text-sky-400 rounded-lg w-1/2 py-2"
                onClick={() => setLocalError('')}
              >
                Fermer
              </button>
            </div>
          )}
        {cart.tandemOrderItems?.map((x, idx) => {
          const itemDetails = items?.find((j) => j.id === x.itemID);
          // eslint-disable-next-line no-return-assign
          return (
            <div key={x?.id} ref={passengerRefs.current[idx] ??= { current: null }} className="scroll-m-20 lg:relative flex flex-col justify-between items-center bg-white lg:rounded-lg w-full h-full lg:w-1/2">
              {/* Head Title */}
              <h3 className="text-base lg:text-lg uppercase mb-3 px-3 lg:px-0 text-center lg:text-left w-full">
                {cart.tandemOrderItems.length === 1
                  ? `vol ${itemDetails?.name}`
                  : `vol ${idx + 1}/${cart.tandemOrderItems.length} ${itemDetails?.name}`}
              </h3>
              {/* Passenger form and calendar */}
              <PassengerSelectDate
                cart={cart}
                setCart={setCart}
                tandemOrderItem={x}
                itemDetails={itemDetails}
                eventsDetails={eventsDetails}
                setEventsDetails={setEventsDetails}
                month={month}
                items={items}
                dates={dates}
                setDates={setDates}
                setMonth={setMonth}
                setError={setError}
                orderIdx={orderIdx}
                setOrderIdx={setOrderIdx}
                passengerRefs={passengerRefs}
                paymentRef={paymentRef}
                setShowPayment={setShowPayment}
              />
              <hr className="my-12 w-2/3 lg:w-full border-gray-200 border-1" />
            </div>
          );
        })}
        <div ref={paymentRef} className="scroll-m-20 relative flex flex-col justify-center items-center w-full lg:w-1/2 h-full gap-4 px-6 mb-6">
          <Contacts
            cart={cart}
            setCart={setCart}
            mailFormat={mailFormat}
            isValidPhoneNumber={isValidPhoneNumber}
            isPossiblePhoneNumber={isPossiblePhoneNumber}
            setShowPayment={setShowPayment}
          />
          <Policy
            cart={cart}
            setCart={setCart}
            eventsDetails={eventsDetails}
            setShowPayment={setShowPayment}
          />
          {(showPayment)
            ? (
              <Payment
                stripePromise={stripePromise}
                cart={cart}
                setCart={setCart}
                setError={setError}
                letUserToEnterPromoOrCashbackCode
              />
            ) : (
              <button
                type="button"
                className="bg-sky-500 rounded-lg shadow-xl text-white px-3 py-3 w-80 font-bold mb-6"
                onClick={() => checkCart()}
              >
                Procéder au paiement
              </button>
            )}
        </div>
      </div>
    </div>
  );
}
