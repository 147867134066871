/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CartDetails from './cartDetails';

export default function Navbar({
  cart,
  purchaseStep,
  setPurchaseStep,
  isScroll,
  items,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [animate, setAnimate] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showCollapseMenu, setShowCollapseMenu] = useState(false);
  const [showBiplaceMenu, setShowBiplaceMenu] = useState(false);
  const [showTrainingMenu, setShowTrainingMenu] = useState(false);
  const [showOtherMenu, setShowOtherMenu] = useState(false);
  const [showNav, setShowNav] = useState(false);
  useEffect(() => {
    if (cart?.grandTotal > 0) {
      if (purchaseStep === 0) {
        setShowCart(true);
      }
      setAnimate(true);
    }
  }, [cart?.grandTotal]);
  useEffect(() => {
    if (location.pathname === '/' && !location?.state && cart.total === 0) {
      const timeOut = setTimeout(() => setShowNav(true), 4000);
      return () => {
        clearTimeout(timeOut);
      };
    }
    setShowNav(true);
    return () => {};
  }, []);
  if (showNav) {
    return (
      <div className={`${location.pathname === '/' && 'animate-fadeInHome'} relative z-50 text-white font-semibold
      ${(isScroll)
        ? ' lg:fixed bg-sky-600/80 lg:h-[80px] w-full'
        : ' relative lg:absolute w-full rounded-none lg:p-0 bg-sky-900/20 lg:h-[100px]'}`}
      >
        {/* Small menu */}
        <div className="absolute lg:hidden flex flex-col justify-center w-full p-2">
          <div className={`rounded-lg
          ${showCollapseMenu
            ? 'bg-sky-600/90'
            : 'bg-sky-600/50'}`}
          >
            <div className="flex items-center justify-between w-full min-w-64 h-16">
              {/* Logo */}
              <div className="flex justify-center bg-transparent w-[180px] h-auto ml-3">
                <img
                  alt="Addict Parapente"
                  src="/image/addict/logo-centre-addict_parapente.png"
                />
              </div>
              <button
                type="button"
                className={`px-6 h-full text-right
                ${showCollapseMenu
                  ? ''
                  : ' lg:hidden block'}`}
                aria-label="menu"
                onClick={() => setShowCollapseMenu(!showCollapseMenu)}
              >
                <i className="fas fa-bars text-xl" />
              </button>
            </div>
            <div className={`w-full
            ${showCollapseMenu
              ? 'flex justify-center'
              : 'hidden'}`}
            >
              <div className="flex flex-col justify-center items-center w-full h-full">
                <nav className="flex flex-col justify-center items-start w-full lg:w-1/3 text-xs h-full">
                  <div className="flex flex-col items-center w-full px-2">
                    <button
                      type="button"
                      className="w-full px-4 py-4 bg-transparent text-white text-left mb-3"
                      onClick={() => {
                        navigate('/');
                      }}
                    >
                      Accueil
                    </button>
                    <button
                      type="button"
                      className="w-full px-4 py-4 bg-transparent text-white text-left mb-3"
                      onClick={() => {
                        setShowTrainingMenu(false);
                        setShowOtherMenu(false);
                        setShowBiplaceMenu(!showBiplaceMenu);
                      }}
                    >
                      Bapt&ecirc;me de Parapente
                      <div className={`flex flex-col w-full mt-3 text-left
                        ${showBiplaceMenu
                        ? ' block'
                        : ' hidden'}`}
                      >
                        <a
                          href={`${window.location.origin}#faire-un-vol-en-parapente`}
                          className="w-full px-3 py-3"
                          onClick={() => setShowCollapseMenu(false)}
                        >
                          <img
                            src="/image/icons/tickets.svg"
                            alt="tickets"
                            className="inline w-[30px] h-auto mr-1"
                          />
                          Réserver
                        </a>
                        <a
                          href={`${window.location.origin}#bapteme-faq`}
                          className="w-full px-3 py-3"
                          onClick={() => setShowBiplaceMenu(false)}
                        >
                          <i className="fas fa-question-circle mr-4 ml-2" />
                          Vos questions
                        </a>
                      </div>
                    </button>
                    {/* Courses menu */}
                    <button
                      type="button"
                      className="w-full px-4 py-4 bg-transparent text-white text-left mb-3"
                      onClick={() => {
                        setShowBiplaceMenu(false);
                        setShowOtherMenu(false);
                        setShowTrainingMenu(!showTrainingMenu);
                      }}
                    >
                      Formation Parapente
                      <div className={`flex flex-col w-full mt-3 text-left
                        ${showTrainingMenu
                        ? ' block'
                        : ' hidden'}`}
                      >
                        <a
                          href={`${window.location.origin}#apprendre-a-voler-parapente`}
                          className="w-full px-3 py-3"
                          onClick={() => setShowCollapseMenu(false)}
                        >
                          <img
                            src="/image/icons/tickets.svg"
                            alt="tickets"
                            className="inline w-[30px] h-auto mr-1"
                          />
                          Réserver
                        </a>
                        <a
                          href={`${window.location.origin}#apprendre-faq`}
                          className="w-full px-3 py-3"
                          onClick={() => setShowBiplaceMenu(false)}
                        >
                          <i className="fas fa-question-circle mr-4 ml-2" />
                          Vos questions
                        </a>
                      </div>
                    </button>
                    <a className="w-full px-4 py-4 bg-transparent text-white text-left" href={`${window.location.origin}/bon_cadeau#`} alt="bon cadeau">
                      Bon Cadeau
                    </a>
                    {/* Other menu */}
                    <button
                      type="button"
                      className="w-full px-4 py-4 bg-transparent text-white text-left mb-3"
                      onClick={() => {
                        setShowTrainingMenu(false);
                        setShowBiplaceMenu(false);
                        setShowOtherMenu(!showOtherMenu);
                      }}
                    >
                      Autre
                      <div className={`flex flex-col w-full mt-3 text-left mb-3
                        ${showOtherMenu
                        ? ' block'
                        : ' hidden'}`}
                      >
                        <Link
                          className="w-full lg:w-auto px-3 mb-3"
                          to="https://voilerie-reunion.com"
                          state={{ from: location }}
                          onClick={() => setShowOtherMenu(false)}
                        >
                          Addict Voilerie
                        </Link>
                        <Link
                          className="w-full lg:w-auto px-3"
                          to="https://addict-burger.com"
                          state={{ from: location }}
                          onClick={() => setShowOtherMenu(false)}
                        >
                          Addict Burger
                        </Link>
                      </div>
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Big menu */}
        <div className="lg:block hidden w-full h-full">
          <div className="flex flex-row justify-start items-center w-full h-full bg-gradient-to-l from-sky-400/40 to-sky-700/40">
            <div className="flex flex-row justify-start items-center w-10/12 xl:w-3/4 h-full">
              {/* Logo */}
              <div className="w-[140px] min-w-[140px] lg:w-[200px] lg:min-w-[200px] h-auto bg-transparent ml-3">
                <img
                  alt="Addict Parapente"
                  src="/image/addict/logo-centre-addict_parapente.png"
                  className="w-full h-full"
                />
              </div>
              {/* Links */}
              <nav className="flex flex-row justify-start items-center w-full gap-6 xl:gap-12 ml-6 xl:ml-12 text-xs h-full">
                <Link
                  className="py-5 text-center hover:font-black"
                  to="/"
                  state={{ from: location }}
                >
                  Accueil
                </Link>
                {/* Tandem */}
                <div className="relative flex flex-row items-center py-5 group cursor-pointer">
                  <div className="flex text-left w-full group-hover:font-black">
                    Bapt&ecirc;me de Parapente
                    <div className="hidden group-hover:block w-1 ml-1 xl:ml-3">
                      <i className="fas fa-angle-down" />
                    </div>
                    <div className="group-hover:hidden w-1 ml-1 xl:ml-3">
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  {/* Tandem Menu */}
                  <div className="hidden group-hover:block absolute bg-white shadow-lg text-gray-600 rounded-lg p-3 left-0 top-[50px] w-[200px]">
                    <a
                      href={`${window.location.origin}#faire-un-vol-en-parapente`}
                      className="w-full lg:w-auto py-5 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800"
                      onClick={() => setShowBiplaceMenu(false)}
                    >
                      Réserver un bapt&ecirc;me à Saint-Leu
                    </a>
                    <hr className="w-full my-3" />
                    <a
                      href={`${window.location.origin}#bapteme-faq`}
                      className="w-full lg:w-auto py-5 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800"
                      onClick={() => setShowBiplaceMenu(false)}
                    >
                      Vos questions
                    </a>
                  </div>
                </div>
                {/* Courses */}
                <div className="relative flex flex-row items-center py-5 group cursor-pointer">
                  <div className="flex text-center w-full group-hover:font-black">
                    Formation Parapente
                    <div className="hidden group-hover:block w-1 ml-1 xl:ml-3">
                      <i className="fas fa-angle-down" />
                    </div>
                    <div className="group-hover:hidden w-1 ml-1 xl:ml-3">
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  {/* Courses Menu */}
                  <div className="hidden group-hover:block absolute bg-white shadow-lg text-gray-600 rounded-lg p-3 left-0 top-[50px] w-[220px]">
                    <a
                      href={`${window.location.origin}#apprendre-a-voler-parapente`}
                      className="w-full lg:w-auto py-5 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800"
                      onClick={() => setShowBiplaceMenu(false)}
                    >
                      R&eacute;server une formation
                    </a>
                    <hr className="w-full my-3" />
                    <a
                      href={`${window.location.origin}#apprendre-faq`}
                      className="w-full lg:w-auto py-5 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800"
                      onClick={() => setShowBiplaceMenu(false)}
                    >
                      Vos questions
                    </a>
                  </div>
                </div>
                <a className="" href={`${window.location.origin}/bon_cadeau#`} alt="bon cadeau">
                  Bon Cadeau
                </a>
                {/* Other */}
                <div className="relative flex flex-row items-center py-5 group cursor-pointer">
                  <div className="flex text-center w-full group-hover:font-black">
                    Autre
                    <div className="hidden group-hover:block w-1 ml-1 xl:ml-3">
                      <i className="fas fa-angle-down" />
                    </div>
                    <div className="group-hover:hidden w-1 ml-1 xl:ml-3">
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  {/* Other Menu */}
                  <div className="hidden group-hover:block absolute bg-white shadow-lg text-gray-600 rounded-lg p-3 left-0 top-[50px] w-[200px]">
                    <a className="w-full lg:w-auto py-5 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800" href="https://voilerie-reunion.com">
                      Addict Voilerie
                    </a>
                    <hr className="w-full my-3" />
                    <a className="w-full lg:w-auto py-5 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800" href="https://addict-burger.com">
                      Addict Burger
                    </a>
                  </div>
                </div>
              </nav>
            </div>
            {/* Cart And languages */}
            <div className="flex flex-row justify-end items-center relative text-center text-xs h-full gap-6 w-2/12 xl:w-1/4">
              {/* Cart Menu */}
              <div className="relative flex flex-row justify-center items-center w-2/3">
                <button
                  type="button"
                  className="relative py-5"
                  onClick={() => {
                    setShowCart(!showCart);
                  }}
                >
                  <i
                    className={`fas fa-cart-shopping mr-1
                      ${animate > 0
                      && 'animate-shake'}`}
                    onAnimationEnd={() => setAnimate(false)}
                  />
                  {(cart?.grandTotal === 0 || !cart)
                    ? 'Pannier'
                    : `Pannier ${cart.grandTotal / 100} €`}
                  {cart?.grandTotal > 0
                    && (
                    <span className="flex absolute right-0 top-0 h-3 w-3 -mx-4">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-300 opacity-75" />
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-300" />
                    </span>
                    )}
                </button>
                <div className={`absolute bg-white shadow-lg text-gray-600 rounded-lg p-3 -left-20 xl:left-0 top-[50px] w-[250px] xl:w-[300px] text-left
                  ${showCart
                  ? ' block'
                  : ' hidden'}`}
                >
                  <h3 className="text-sm mb-3">
                    Mon pannier
                  </h3>
                  {(cart?.tandemOrderItems?.length === 0 || cart === undefined)
                    && (
                      <div className="m-3">
                        <p>
                          Aucune réservations
                        </p>
                      </div>
                    )}
                  {(cart?.isAGift)
                    && (
                      <div className="m-3">
                        <p>
                          {`Un bon cadeau (${cart.grandTotal / 100}€)`}
                        </p>
                      </div>
                    )}
                  <ul>
                    {cart?.tandemOrderItems?.length > 0
                      && (
                        cart?.tandemOrderItems?.map(
                          (x) => (
                            <CartDetails
                              key={x.id}
                              tandemOrderItem={x}
                              items={items}
                            />
                          ),
                        )
                      )}
                  </ul>
                  {!cart.isAGift
                    && (
                      <button
                        type="button"
                        className="AddictSkyButton text-xs w-full mt-6 uppercase"
                        onClick={() => {
                          if (location?.pathname !== '/reserver/bapteme') {
                            navigate('/reserver/bapteme');
                            // return;
                          }
                          setShowCart(false);
                          setPurchaseStep(1);
                        }}
                      >
                        réserver
                      </button>
                    )}
                </div>
              </div>
              {/* Lang Menu */}
              <div className="relative flex flex-row justify-center items-center py-5 group w-1/3">
                <div className="flex text-center w-full group-hover:font-black">
                  <img
                    src="/image/flags/drapeau-site-france.svg"
                    alt="french flag"
                    className="w-[30px] ml-1"
                  />
                </div>
                <div className="hidden group-hover:block absolute bg-white shadow-lg text-gray-600 rounded-lg p-3 -left-20 xl:left-0 top-[50px] w-[120px] text-xs">
                  <a
                    href={`${window.location.origin}#`}
                    className="flex w-full lg:w-auto py-1 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800"
                    onClick={() => setShowBiplaceMenu(false)}
                  >
                    <img
                      src="/image/flags/drapeau-site-usa.svg"
                      alt="french flag"
                      className="w-[30px] mr-1"
                    />
                    Anglais
                  </a>
                  <hr className="w-full my-3" />
                  <a
                    href={`${window.location.origin}#`}
                    className="flex w-full lg:w-auto py-1 shadow-lg lg:shadow-none lg:mr-6 hover:text-gray-800"
                    onClick={() => setShowCollapseMenu(false)}
                  >
                    <img
                      src="/image/flags/drapeau-site-france.svg"
                      alt="french flag"
                      className="w-[30px] mr-1"
                    />
                    Français
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
