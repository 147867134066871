import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ModalNonEmptyCart({
  cart,
  source,
  setModal,
}) {
  const navigate = useNavigate();
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex flex-col justify-center items-center p-5 text-gray-500">
              {source === 'makeAGift'
                && (
                  <>
                    <h3 className="text-base lg:text-lg uppercase">
                      Réservation en cours
                    </h3>
                    <div className="text-xs lg:text-base relative p-6 flex-auto text-center">
                      En continuant sur cette page vous perdrez votre séléction.
                      Si vous souhaitiez conserver vos réservations finaliser d&apos;abord celle-ci
                      et revenez après.
                    </div>
                    <div className="flex flex-row gap-2 w-full">
                      <button
                        type="button"
                        onClick={() => {
                          setModal(false);
                          navigate(0);
                        }}
                        className="w-1/2 border shadow h-10 rounded-lg px-1 hover:bg-sky-400 hover:text-white text-sm lg:text-sm"
                      >
                        Finaliser la réservation
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setModal(false);
                        }}
                        className="w-1/2 border shadow h-10 rounded-lg px-1 hover:bg-sky-400 hover:text-white text-sm lg:text-sm"
                      >
                        Abandonner la réservation
                      </button>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-45 fixed inset-0 z-40 bg-black" />
    </>
  );
}
