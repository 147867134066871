/* eslint-disable react/prop-types */
import React from 'react';
import PaymentMethods from '../PaymentMethods';

export default function TandemFAQSection() {
  return (
    <div className="relative flex flex-col justify-start bg-transparent w-full h-full px-3 lg:px-6 overflow-hidden text-gray-500">
      <div className="relative z-10 flex flex-col justify-center items-center w-full lg:w-full">
        <div className="text-xl lg:text-4xl font-playfair uppercase mb-12 text-center">
          Questions fréquentes pour les baptemes
        </div>
        <div className="flex flex-wrap justify-center items-stretch w-full lg:gap-10">
          <div className="flex flex-col w-full lg:w-5/12 justify-between items-start rounded-lg text-left px-6 text-xs lg:text-sm">
            <div className="mb-3">
              <div className="lg:text-base uppercase text-[#ceb071] mb-3">
                Comment s&apos;habiller ?
              </div>
              Nous vous conseillons d&apos;avoir des chaussures ferm&eacute;es de type baskets
              ou chaussures de randonn&eacute;s, des lunettes de soleil et une petite veste.
              Vous pouvez &ecirc;tre en short ou en pantalon.
            </div>
            <div className="mb-3">
              <div className="lg:text-base uppercase text-[#ceb071] mb-3">
                l&apos;âge, poids et condition physique ?
              </div>
              <p className="lg:text-balance lg:mb-1">
                Nous proposons des vols pour les 5 &agrave; 12 ans appel&eacute;s vol
                &quot;Marmay&quot; (enfant en créole) ainsi que des vols pour les adultes.
                Pour une réservation en ligne, le poids du passager doit &ecirc;tre compris entre 20
                et 80 kg. Dans le cas contraire, nous vous invitons à réserver par
                t&eacute;l&eacute;phone au
                <a href="tel:(0262)692256363" className="px-1">(0262)692-256363</a>
                ou par e-mail à l&apos;adresse suivante:
                <a href="mailto:info@addictparapente.com" className="px-1">info@addictparapente.com</a>
                En dehors du vol &quot;grande découverte&quot; qui demande une bonne
                condition physique, vous devrez simplement &ecirc;tre capable de faire une
                foul&eacute;e au d&eacute;collage et le reste tout se fait en douceur.
              </p>
            </div>
            <div className="mb-3">
              <div className="lg:text-base uppercase text-[#ceb071] mb-3">
                Quels sont les conditions en cas d&apos;annulation de vol ?
              </div>
              En cas d&apos;annulation de notre part, vous recevrez automatiquement un e-mail
              qui vous permettra de choisir un avoir ou un remboursement du ou des vol(s) annulé(s).
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-5/12 justify-between items-start rounded-lg text-left px-6 text-xs lg:text-sm">
            <div className="mb-3">
              <div className="lg:text-base uppercase text-[#ceb071] mb-3">
                Quels sont les moyens de paiement acceptés ?
              </div>
              Nous acceptons les paiements par carte bancaire:
              <PaymentMethods />
            </div>
            <div className="mb-3">
              <div className="lg:text-base uppercase text-[#ceb071] mb-3">
                O&ugrave; a lieu le vol ?
              </div>
              A Saint-Leu de l&apos;île de la R&eacute;union
              entre le d&eacute;collage dit
              les &quot;Colimaçons&quot; &agrave; 800m d&apos;altitude
              ou &agrave; 1600m d&apos;altitude en fonction du vol
              choisi.
              <br />
              Le site d&apos;att&eacute;rissage se situe sur la plage &agrave; Saint-Leu
              &agrave; c&ocirc;t&eacute; de K&eacute;lonia.
            </div>
            <div className="mb-3">
              <div className="lg:text-base uppercase text-[#ceb071] mb-3">
                Est-ce possible de faire un tandem en situation d&apos;handicap ?
              </div>
              <div>
                Cela dépend du handicap. Les passagers en situation de handicap doivent réserver
                par t&eacute;l&eacute;phone au
                <a href="tel:(0262)692256363" className="px-1">(0262)692-256363</a>
                ou par e-mail à l&apos;adresse suivante:
                <a href="mailto:info@addictparapente.com" className="px-1">info@addictparapente.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
