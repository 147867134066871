import { useEffect, useState } from 'react';

export default function useScroll() {
  const [isScroll, setIsScroll] = useState(false);
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY + window.innerHeight > 950) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return {
    isScroll,
  };
}
