/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import GetPartOfDayByQuarter from './GetPartOfDayByQuarter';

export default function ConfirmDate({
  cart,
  setCart,
  tandemOrderItem,
  eventsDetails,
  setEventsDetails,
  setShowAgeAndWeightForm,
  setShowIdentityForm,
  setShowCompletedOrderItem,
  setShowPayment,
}) {
  const event = eventsDetails?.find((j) => j.orderItemID === tandemOrderItem.id);
  if (event) {
    return (
      <>
        <div className="flex flex-col justify-center items-center text-sm lg:text-base text-center lg:mb-6 w-full">
          <p className="mb-3">
            Confirmez la date suivante
          </p>
          <span className="font-semibold mb-3">
            {`Le ${new Date(event.date).toLocaleDateString('fr-FR', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })} en ` }
            <GetPartOfDayByQuarter timeStr={event?.startTime} />
          </span>
          <span className="block font-semibold text-xs text-gray-400 mb-3">
            Nous indiquerons l&apos;heure exacte la veille du rendez-vous
            en fonction de la météo
          </span>
        </div>
        {/* Buttons */}
        <div className="flex flex-row gap-2 w-full">
          <button
            type="button"
            className="bg-white text-sky-400 border rounded-lg py-2 shadow-lg hover:shadow-xl w-full uppercase text-xs lg:text-sm font-bold"
            onClick={() => {
              setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.map((j) => {
                  if (j.id === tandemOrderItem.id) {
                    return {
                      ...j,
                      eventID: null,
                    };
                  }
                  return j;
                }),
              });
              setEventsDetails(eventsDetails.filter(
                (x) => x.orderItemID === tandemOrderItem.id,
              ));
              setShowAgeAndWeightForm(true);
              setShowIdentityForm(false);
              setShowCompletedOrderItem(false);
              setShowPayment(false);
            }}
          >
            Choisir une autre date
          </button>
          <button
            type="button"
            className="bg-sky-400 text-white rounded-lg py-2 shadow-lg hover:shadow-xl w-full uppercase text-xs lg:text-sm font-bold"
            onClick={() => {
              setShowIdentityForm(true);
            }}
          >
            Confirmer
          </button>
        </div>
      </>
    );
  }
}
