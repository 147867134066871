/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

export default function CheckPassengerIdentity({
  tandemOrderItem,
  isValidIdentity,
  setIsValidIdentity,
  setIsValidFirstName,
  setIsValidLastName,
  nameFormat,
}) {
  const [content, setContent] = useState('');
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
    if (!tandemOrderItem?.firstName) {
      setIsValidFirstName(false);
      setIsValidIdentity(false);
      setContent('Renseignez le prénom de ce passager');
      return;
    }
    if (tandemOrderItem?.firstName.match(nameFormat) === null) {
      setIsValidFirstName(false);
      setIsValidIdentity(false);
      setContent('Le prénom de ce passager n\'est pas valide');
      return;
    }
    setIsValidFirstName(true);
    if (!tandemOrderItem?.lastName) {
      setIsValidLastName(false);
      setIsValidIdentity(false);
      setContent('Renseignez le nom de ce passager');
      return;
    }
    if (tandemOrderItem?.lastName.match(nameFormat) === null) {
      setIsValidLastName(false);
      setIsValidIdentity(false);
      setContent('Le nom de ce passager n\'est pas valide');
      return;
    }
    setIsValidLastName(true);
    if (!tandemOrderItem?.isPassengerAbleToRun) {
      setIsValidIdentity(false);
      setContent('Confirmez la condition spécifique');
      return;
    }
    setIsValidIdentity(true);
    setContent('Vous pouvez séléctionner une date sur le calendrier');
  }, [tandemOrderItem]);

  if (!isValidIdentity) {
    return (
      <div className={`relative w-auto h-auto mb-3 bg-sky-400 rounded-xl shadow-lg text-white ${animate ? 'animate-fade' : ''}`} onAnimationEnd={() => setAnimate(false)}>
        <div className="absolute left-2 top-1/2 -translate-y-1/2 rounded-full bg-white text-sky-400 shadow-lg w-6 h-6 text-center">
          <i className="fas fa-question text-sm font-bold" />
        </div>
        <div className="w-full px-12 py-3 flex justify-center items-center">
          {content}
        </div>
      </div>
    );
  }
}
