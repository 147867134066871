/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

import Navbar from '../components/navigation/Navbar';
import ByAddictSection from '../components/home/ByAddictSection';
import CourseSection from '../components/home/CourseSection';
import HeadVideoSection from '../components/home/HeadVideoSection';
import TandemSection from '../components/home/TandemSection';
import Footer from '../components/navigation/Footer';
import PartnersSection from '../components/home/PartnersSection';
import Images from '../components/home/Images';
import ImagesAfterTandems from '../components/home/ImagesAfterTandems';
import ImagesAfterCourses from '../components/home/ImagesAfterCourses';
import useScroll from '../components/useScroll';
import FixedCartBtn from '../components/navigation/FixedCartBtn';
import ModalError from '../components/ModalError';
import TandemFAQSection from '../components/home/TandemFAQSection';
import CourseFAQSection from '../components/home/CourseFAQSection';
import NavUpBtn from '../components/navigation/NavUpBtn';

export default function Home({
  cart,
  tandemItems,
  courseItems,
  purchaseStep,
  setPurchaseStep,
  AddOrDeleteOrderItem,
  error,
  setError,
  isPILoading,
}) {
  const { isScroll } = useScroll();

  return (
    <main className="relative font-montserrat">
      {(error !== '')
        && (
          <ModalError error={error} setError={setError} />
        )}
      <Navbar
        isScroll={isScroll}
        cart={cart}
        purchaseStep={purchaseStep}
        setPurchaseStep={setPurchaseStep}
        items={tandemItems}
      />
      <section className="w-full h-screen shadow-xl">
        <HeadVideoSection />
      </section>
      <section id="faire-un-vol-en-parapente" className="scroll-m-20 relative bg-white w-full my-20">
        <TandemSection
          cart={cart}
          items={tandemItems}
          AddOrDeleteOrderItem={AddOrDeleteOrderItem}
          isPILoading={isPILoading}
        />
      </section>
      <section className="relative flex flex-col flex-wrap h-[360px] w-full lg:hidden">
        <ImagesAfterTandems />
      </section>
      <section id="bapteme-faq" className="scroll-m-20 relative bg-white w-full my-20">
        <TandemFAQSection />
      </section>
      <section className="h-auto shadow-xl">
        <PartnersSection />
      </section>
      <section id="apprendre-a-voler-parapente" className="scroll-m-20 relative bg-white w-full my-20">
        {!courseItems.isLoading
        && (
          <CourseSection items={courseItems} />
        )}
      </section>
      <section className="relative flex flex-col flex-wrap h-[360px] w-full lg:hidden">
        <ImagesAfterCourses />
      </section>
      <section id="apprendre-faq" className="scroll-m-20 relative bg-white w-full my-20">
        <CourseFAQSection />
      </section>
      <section className="flex flex-col lg:flex-row flex-wrap h-auto w-full shadow-xl">
        <Images />
      </section>
      <section className="relative flex justify-center bg-white w-full h-[1000px] px-3 lg:px-48 mt-20 text-zinc-500">
        <ByAddictSection />
      </section>
      <Footer />
      {isScroll
        && (
          <NavUpBtn cart={cart} purchaseStep={purchaseStep} />
        )}
      {(cart?.grandTotal > 0 && purchaseStep === 0)
        && (
          <FixedCartBtn
            cart={cart}
            setPurchaseStep={setPurchaseStep}
            items={tandemItems}
          />
        )}
    </main>
  );
}
