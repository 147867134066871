/* eslint-disable react/prop-types */
import React from 'react';

export default function Images() {
  return (
    <div className="w-full h-44 lg:h-80">
      <div className="relative w-full h-full bg-cover bg-center bg-fixed" style={{ backgroundImage: 'url("/image/paragliding/training/formation-addictparapente-n4-2.webp")' }}>
        <div className="absolute w-full h-full bg-black/50 inset-x-0" />
      </div>
    </div>
  );
}
