/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CountDownExpirationDate({
  initialTime,
}) {
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, []); // The empty dependency array ensures the effect runs only once on mount

  // Convert seconds to hours, minutes, and seconds
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;
  if (minutes === 0 && seconds === 0) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl px-3 lg:px-0">
            <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex flex-col justify-center items-center p-5 text-gray-500">
                <h3 className="text-sm lg:text-xl mb-6">
                  Votre pannier a expiré !
                </h3>
                <button
                  type="button"
                  onClick={() => {
                    navigate(0);
                  }}
                  className="w-full border shadow-lg hover:shadow-xl h-10 rounded-lg px-1"
                >
                  Recommencer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-45 fixed inset-0 z-[45] bg-black" />
      </>
    );
  }
  if (minutes < 2) {
    return (
      <div className="fixed bottom-2 lg:bottom-1/2 right-1/2 lg:right-20 px-3 py-3 bg-white border rounded-full shadow-lg normal-case z-50 w-auto text-xs text-center">
        Votre pannier expire dans
        <br />
        <span className="text-amber-400">
          {`${minutes}:${seconds}`}
        </span>
      </div>
    );
  }
  return (
    <div className="fixed bottom-2 lg:bottom-1/2 right-1/2 lg:right-20 px-3 py-3 bg-white/90 border rounded-full shadow-lg normal-case z-50 w-auto text-xs text-center">
      Votre pannier expire dans
      <br />
      {`${minutes}:${seconds}`}
    </div>
  );
}
