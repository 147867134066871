/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../useWindowsDimensions';

export default function HeadVideoSection() {
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
  };
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    // if (isSafari() && videoParentRef.current) {
    if (isSafari()) {
      // setShouldUseImage(true);
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        // player.playsinline = true;
        player.muted = true;
        player.setAttribute('muted', ''); // leave no stones unturned :)
        player.setAttribute('playsinline', true); // seems better ?
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = 'none';
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  const [slide, setSlide] = useState(0);

  useEffect(() => {
    let newSlide = 0;
    let delay = 0;
    if (slide === 0) {
      delay = 1000;
      newSlide = 1;
    }
    if (slide === 1) {
      delay = 3800;
      newSlide = 2;
    }
    if (slide === 2) {
      delay = 13500;
      newSlide = 3;
    }
    if (slide === 3) {
      delay = 6000;
      newSlide = 4;
    }
    if (slide === 4) {
      delay = 4700;
      newSlide = 0;
    }
    const timeOut = setTimeout(() => setSlide(newSlide), delay);
    return () => {
      clearTimeout(timeOut);
    };
  }, [slide]);
  const [isLandscapePhoneSize, setIsLandscapePhoneSize] = useState(false);
  const [isPortraitPhoneSize, setIsPortraitPhoneSize] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width <= '640') {
      if (height > width) {
        setIsLandscapePhoneSize(false);
        setIsPortraitPhoneSize(true);
        return;
      }
      setIsPortraitPhoneSize(false);
      setIsLandscapePhoneSize(true);
    }
    setIsPortraitPhoneSize(false);
    setIsLandscapePhoneSize(false);
  }, [height, width]);

  return (
    <div className="relative flex items-center justify-center overflow-hidden m-0 p-0 w-full h-full scale-100 bg-gray-700">
      {/* Video */}
      {shouldUseImage
        && (
          <img src="/video/addicttrailer_large.mp4" alt="addict parapente trailer" className="w-full h-full object-cover" />
        )}
      {(!shouldUseImage && !isLandscapePhoneSize && !isPortraitPhoneSize)
        && (
          <div
            className="flex items-center justify-center m-0 p-0 w-full h-full scale-100"
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
              <video
                controls
                disablePictureInPicture
                controlsList="nodownload"
                autoplay
                playsinline
                loop
                muted
                preload="metadata"
                poster="/image/slide-2.webp"
                class="block w-full min-w-full max-w-full h-auto"
              >
                <source
                  src="/video/addicttrailer_large.mp4"
                  type="video/mp4"
                />
                <source
                  src="/video/addicttrailer_large.webm"
                  type="video/webm"
                />
              </video>`,
            }}
          />
        )}
      {(!shouldUseImage && isLandscapePhoneSize)
        && (
          <div
            className="flex items-center justify-center m-0 p-0 w-full h-full scale-100"
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
              <video
                controls
                disablePictureInPicture
                controlsList="nodownload"
                autoplay
                playsinline
                loop
                muted
                preload="metadata"
                poster="/image/slide-2.webp"
                class="block lg:w-full lg:min-w-full lg:max-w-full h-full lg:h-auto w-auto"
              >
                <source
                  src="/video/addicttrailer_small_landscape.mp4"
                  type="video/mp4"
                />
                <source
                  src="/video/addicttrailer_small_landscape.webm"
                  type="video/webm"
                />
              </video>`,
            }}
          />
        )}
      {(!shouldUseImage && isPortraitPhoneSize)
        && (
          <div
            className="flex items-center justify-center m-0 p-0 w-full h-full scale-100"
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
              <video
                controls
                disablePictureInPicture
                controlsList="nodownload"
                autoplay
                playsinline
                loop
                muted
                preload="metadata"
                poster="/image/addicttrailer_small_portrait.webp"
                class="block h-full w-auto"
              >
                <source
                  src="/video/addicttrailer_small_portrait.mp4"
                  type="video/mp4"
                />
                <source
                  src="/video/addicttrailer_small_portrait.webm"
                  type="video/webm"
                />
              </video>`,
            }}
          />
        )}
      {/* Slide */}
      <div className="absolute flex w-full lg:w-full h-full overflow-hidden bg-sky-300/10">
        {(slide === 1)
          && (
            <div className="flex justify-center items-center animate-fadeInHome bg-transparent w-full h-full px-8">
              <div className="flex flex-col justify-center items-center text-white uppercase text-center rounded-xl w-full lg:w-[800px] p-4">
                <div className="flex mb-6 lg:mb-12 gap-3">
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                </div>
                <p className="font-playfair uppercase font-black text-lg lg:text-4xl mb-6 lg:mb-12 textShadow-lg">
                  Le parapente sur l&apos;&icirc;le de la Réunion
                  <br />
                  +30 ans d&apos;
                  <span className="text-[#ceb071]">
                    experiences
                  </span>
                </p>
                <p className="text-xs lg:text-xl font-normal mb-6 lg:mb-12">
                  Structure labélisée Qualité Tourisme National
                </p>
                <div className="flex justify-center items-stretch text-white w-full pt-3 mb-3 lg:mb-12 gap-4">
                  <a
                    href="https://www.qualite-tourisme.gouv.fr/fr"
                    className="w-16 lg:w-20 h-13 lg:h-auto bg-transparent rounded-xl p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="qrt"
                      src="image/certifications/logo-qnhd.svg"
                    />
                  </a>
                  <a
                    href="https://www.reunion.fr/organisez/conseils-de-voyage/labels-a-la-reunion/qualite-tourisme-ile-de-la-reunion/"
                    className="w-24 lg:w-32 h-19 lg:h-auto bg-transparent rounded-xl p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="qrt-hd"
                      src="image/certifications/qrt-hd.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          )}
        {(slide === 2)
          && (
            <div className="flex justify-center items-center animate-fadeInHome bg-transparent w-full h-full px-8">
              <div className="flex flex-col justify-center items-center text-white text-center rounded-xl w-full lg:w-[800px] p-4">
                <h3 className="text-3xl lg:text-5xl mb-6 lg:mb-12 font-playfair font-black uppercase">
                  La billetterie du
                  <br />
                  <span className="text-[#ceb071]">
                    parapente
                  </span>
                </h3>
                <div className="flex gap-1 mb-6 lg:mb-12">
                  <img
                    src="/image/icons/tickets.svg"
                    alt="tickets"
                    className="w-auto h-[30px] lg:h-[40px] ml-3 animate-shake"
                  />
                </div>
                <p className="mb-6 lg:mb-12 text-xs lg:text-sm uppercase">
                  Réservez en ligne un vol en parapente sur notre billetterie
                  <br />
                  Votre bapt&ecirc;me (tandem),
                  sur l&apos;&icirc;le de la R&eacute;union à Saint-Leu
                </p>
                <a
                  href={`${window.location.origin}/#faire-un-vol-en-parapente`}
                  className="rounded-lg px-5 py-2 w-80 bg-sky-500 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-sky-400 transition-all ease-out duration-300"
                >
                  Acc&eacute;der à la billetterie
                </a>
              </div>
            </div>
          )}
        {(slide === 3)
          && (
            <div className="flex justify-center items-center animate-fade bg-transparent w-full h-full px-8">
              <div className="flex flex-col justify-center items-center text-white uppercase text-center rounded-xl w-full lg:w-[800px] p-4">
                <h3 className="text-2xl lg:text-5xl mb-6 lg:mb-12 font-playfair font-black animate-bounce-up">
                  Formation
                  <br />
                  <span className="text-[#ceb071]">
                    parapente
                  </span>
                </h3>
                <div className="flex justify-center items-center gap-6 lg:gap-12 mb-6 lg:mb-12">
                  <img
                    src="/image/icons/casqueN1.svg"
                    alt="casque niveau 1"
                    className="w-auto h-[20px] lg:h-[30px] animate-shake"
                  />
                  <img
                    src="/image/icons/casqueN20.svg"
                    alt="casque niveau 3"
                    className="w-auto h-[20px] lg:h-[30px] animate-shake"
                  />
                  <img
                    src="/image/icons/casqueN2.svg"
                    alt="casque niveau 2"
                    className="w-auto h-[20px] lg:h-[30px] animate-shake"
                  />
                </div>
                <p className="mb-6 lg:mb-12 text-xs lg:text-sm">
                  Apprendre à voler en parapente,
                  sur l&apos;ile de la Réunion à Saint-Leu
                </p>
                <a
                  href={`${window.location.origin}/#apprendre-a-voler-parapente`}
                  className="rounded-lg px-5 py-2 w-80 bg-sky-500 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-sky-400 transition-all ease-out duration-300"
                >
                  R&eacute;server une formation
                </a>
              </div>
            </div>
          )}
        {(slide === 4)
          && (
            <div className="flex justify-center items-center animate-fadeInHome bg-transparent w-full h-full px-8">
              <div className="flex flex-col justify-center items-center text-white uppercase text-center rounded-xl w-full lg:w-[800px] p-4">
                <div className="flex mb-6 lg:mb-12 gap-3">
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                  <i className="fa fa-star text-xs" />
                </div>
                <p className="font-playfair uppercase font-black text-lg lg:text-4xl mb-6 lg:mb-12">
                  Le parapente sur l&apos;&icirc;le de la Réunion
                  <br />
                  +30 ans d&apos;
                  <span className="text-[#ceb071]">
                    experiences
                  </span>
                </p>
                <p className="text-xs lg:text-xl font-normal mb-6 lg:mb-12">
                  Structure labélisée Qualité Tourisme National
                </p>
                <div className="flex justify-center items-stretch text-white w-full pt-3 mb-3 lg:mb-12 gap-4">
                  <a
                    href="https://www.qualite-tourisme.gouv.fr/fr"
                    className="w-16 lg:w-20 h-13 lg:h-auto bg-transparent rounded-xl p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="qrt"
                      src="image/certifications/logo-qnhd.svg"
                    />
                  </a>
                  <a
                    href="https://www.reunion.fr/organisez/conseils-de-voyage/labels-a-la-reunion/qualite-tourisme-ile-de-la-reunion/"
                    className="w-24 lg:w-32 h-19 lg:h-auto bg-transparent rounded-xl p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="qrt-hd"
                      src="image/certifications/qrt-hd.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
