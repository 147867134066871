/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';
import useScroll from '../components/useScroll';

export default function PaymentStatus({
  stripePromise,
  cart,
  // setCart,
  purchaseStep,
  setPurchaseStep,
  tandemItems,
}) {
  const [pStatus, setPStatus] = useState('');
  const urlInfo = new URL(window.location);
  const piInfo = urlInfo.searchParams.get('payment_intent');
  const free = urlInfo.searchParams.get('free');
  const { isScroll } = useScroll();
  useEffect(() => {
    if (!free) {
      if (!stripePromise) return;

      stripePromise.then(async (stripe) => {
        const url = new URL(window.location);
        const clientSecret = url.searchParams.get('payment_intent_client_secret');
        const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        if (error) {
          setPStatus('error');
        }
        switch (paymentIntent.status) {
          case 'succeeded':
            setPStatus('succeeded');
            break;
          case 'processing':
            setPStatus('processing');
            break;
          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setPStatus('requires_payment_method');
            break;
          default:
            setPStatus('error');
            break;
        }
      });
    } else {
      setPStatus('succeeded');
    }
  }, [free, stripePromise]);

  return (
    <main className="w-full font-montserrat text-gray-600">
      <Navbar
        cart={cart}
        purchaseStep={purchaseStep}
        setPurchaseStep={setPurchaseStep}
        isScroll={isScroll}
        items={tandemItems}
      />
      <img
        alt="status_image"
        className="object-cover w-full h-44 lg:h-80"
        src="/image/paragliding/tandem/biplace-marmay-0.jpg"
      />
      <section className="flex items-center justify-center w-full lg:w-full p-6">
        <div className=" w-full lg:w-8/12 p-3 lg:p-12 mb-6">
          {pStatus === 'error'
            && (
              <div>
                Désolé une erreur s&apos;est produite...
                Veuillez recommencer ou nous contacter.
              </div>
            )}
          {pStatus === 'succeeded'
            && (
              <div className="text-center">
                <div className="flex justify-left mb-6">
                  <div className="flex items-center justify-center bg-emerald-50 rounded-full w-10 lg:w-20 h-10 lg:h-20 p-3">
                    <i className="fas fa-check text-xl lg:text-3xl text-emerald-400" />
                  </div>
                  <div className="uppercase font-semibold ml-6 lg:ml-12 text-left">
                    <h3 className="text-xl lg:text-4xl">
                      Confirmation
                    </h3>
                    <h3 className="text-base lg:text-xl">
                      de votre réservation
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row border rounded-lg">
                  <div className="flex flex-col items-center justify-center border-r w-full lg:w-1/2 p-6 text-xs lg:text-sm">
                    <h4 className="text-xl font-semibold uppercase mb-6">
                      Merci !
                    </h4>
                    <h4 className="text-sm lg:text-base font-semibold uppercase mb-6">
                      Commande Numéro:
                      <br />
                      {`${piInfo.split('_')[1].substring(0, 4)}`}
                    </h4>
                    <p className="mb-6">
                      Vous allez recevoir un mail de confirmation dès la réception du paiement.
                      <br />
                      (Pensez à vérifier dans votre boite mail et vos indésirables).
                    </p>
                    <p className="">
                      Vous pouvez fermer cette fenêtre ou continuer à découvrir notre
                      site
                    </p>
                  </div>
                  <div className="text-left w-full lg:w-1/2">
                    <img
                      alt="activity"
                      className="object-cover w-full h-36 rounded-tr-lg"
                      src="/image/activities/activity-1.webp"
                      loading="lazy"
                    />
                    <div className="p-6">
                      <h4 className="text-lg font-semibold uppercase mb-3">
                        Addict
                      </h4>
                      <ul className="text-xs">
                        <li className="mx-2 mb-3">
                          <a className=" py-4" href="tel:(0262) 692-256363">
                            <i className="fas fa-phone mr-2" />
                            (0262) 692-256363
                          </a>
                        </li>
                        <li className="mx-2 mb-3">
                          <i className="fa-solid fa-location-dot mr-2" />
                          5 rue des Ébénistes, Saint-Leu 97436, Ile de la Réunion
                        </li>
                        <li className="mx-2 mb-3">
                          <i className="fa-solid fa-square-parking mr-2" />
                          Places de parking gratuites autour de la boutique
                        </li>
                        <li className="mx-2 mb-3">
                          <i className="fa-solid fa-utensils mr-2" />
                          Sur place Café & Restauration
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {pStatus === 'processing'
            && (
              <div>
                Votre paiement est en cours.
                Nous vous préviendrons lorsqu&apos;il nous l&apos;aurons reçu.
              </div>
            )}
          {pStatus === 'requires_payment_method'
            && (
              <div>
                Le paiement à échoué. Veuillez recommencer avec un nouveau moyen de paiement.
              </div>
            )}
        </div>
      </section>
      <Footer />
    </main>
  );
}
