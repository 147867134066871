/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import PhoneInput from 'react-phone-number-input';

export default function Contacts({
  cart,
  setCart,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  mailFormat,
  setShowPayment,
}) {
  return (
    <>
      <div className="text-sm lg:text-lg uppercase text-left w-full">
        Moyen de contact
        <p className="normal-case text-xs text-left mb-3 w-full text-gray-400">
          Vous recevrez le détails de la commande, l&apos;heure et le lieu du rendez-vous
          sur ce contact
        </p>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center mb-6 w-full lg:gap-2">
        <label htmlFor="phone" className="flex flex-col mb-3 lg:mb-0 w-full">
          <p className="text-base">
            Téléphone
          </p>
          <div className="relative flex items-center shadow rounded w-full">
            <PhoneInput
              id="phone"
              international
              name="phone"
              defaultCountry="FR"
              countries={['FR', 'RE']}
              value={cart?.phone}
              onChange={(e) => {
                setCart({
                  ...cart,
                  phone: e,
                });
                setShowPayment(false);
              }}
            />
            {(cart?.phone
              && (
                isValidPhoneNumber(cart?.phone)
                && isPossiblePhoneNumber(cart?.phone)
              ))
              ? <i className="absolute right-2 lg:right-3 ml-3 text-green-400 fa-solid fa-check" />
              : <i className="absolute right-2 lg:right-3 ml-3 text-rose-400 fa-solid fa-xmark" />}
          </div>
        </label>
        <label htmlFor="email" className="flex flex-col lg:mb-0 w-full">
          <p className="text-base">
            Mail
          </p>
          <div className="flex items-center shadow rounded w-full">
            <input
              type="text"
              id="email"
              className="appearance-none outline-none px-3 py-3 w-full rounded"
              value={cart.email || ''}
              onChange={(e) => {
                setCart({
                  ...cart,
                  email: e?.target?.value,
                });
                setShowPayment(false);
              }}
            />
            {(cart?.email && cart?.email.match(mailFormat) !== null)
              && <i className="mx-2 inline text-green-400 fa-solid fa-check" />}
            {(!cart?.email || cart?.email.match(mailFormat) === null)
              && <i className="mx-2 inline text-rose-400 fa-solid fa-xmark" />}
          </div>
        </label>
      </div>
      {/* <div className="text-sm lg:text-xl uppercase text-left w-full">
        Contact de confirmation
        <p className="normal-case text-xs text-left mb-3 w-full text-gray-400">
          Pour recevoir l&apos;heure et le lieu du rendez-vous à une personne différente de la
          personne qui commande ajoutez, ci-dessous, un moyen de contact au passager que vous
          souhaitez.
        </p>
      </div>
      {cart?.tandemOrderItems?.map((x) => {
        if (x.contactPassenger) {
          return (
            <div className="w-full px-3">
              <h3 className="text-sm lg:text-base uppercase">
                {`Contact pour ${x.firstName} ${x.lastName}`}
              </h3>
              <div className="flex flex-col lg:flex-row justify-center items-center mb-6 w-full lg:gap-2">
                <label htmlFor="phone" className="flex flex-col mb-3 lg:mb-0 w-full">
                  <p className="text-base">
                    Téléphone
                  </p>
                  <div className="relative flex items-center shadow rounded w-full">
                    <PhoneInput
                      id="phone"
                      international
                      name="phone"
                      defaultCountry="FR"
                      countries={['FR', 'RE']}
                      value={x?.phone}
                      onChange={(e) => {
                        setCart({
                          ...cart,
                          tandemOrderItems: cart.tandemOrderItems.map((j) => {
                            if (j.id === x.id) {
                              return {
                                ...j,
                                phone: e,
                              };
                            }
                            return j;
                          }),
                        });
                      }}
                    />
                    {(x?.phone
                      && (
                        isValidPhoneNumber(x?.phone)
                        && isPossiblePhoneNumber(x.phone)
                      ))
                      ? <i className="absolute right-2 lg:right-3 ml-3 text-green-400 fa-solid fa-check" />
                      : <i className="absolute right-2 lg:right-3 ml-3 text-rose-400 fa-solid fa-xmark" />}
                  </div>
                </label>
                <label htmlFor="email" className="flex flex-col lg:mb-0 w-full">
                  <p className="text-base">
                    Mail
                  </p>
                  <div className="flex items-center shadow rounded w-full">
                    <input
                      type="text"
                      id="email"
                      className="appearance-none outline-none px-3 py-3 w-full rounded"
                      value={x.email || ''}
                      onChange={(e) => setCart({
                        ...cart,
                        tandemOrderItems: cart.tandemOrderItems.map((j) => {
                          if (j.id === x.id) {
                            return {
                              ...j,
                              email: e?.target?.value,
                            };
                          }
                          return j;
                        }),
                      })}
                    />
                    {(x?.email && x?.email.match(mailFormat) !== null)
                      && <i className="mx-2 inline text-green-400 fa-solid fa-check" />}
                    {(!x?.email || x?.email.match(mailFormat) === null)
                      && <i className="mx-2 inline text-rose-400 fa-solid fa-xmark" />}
                  </div>
                </label>
              </div>
            </div>
          );
        }
        return (
          <button
            type="button"
            className="bg-white hover:bg-sky-400 hover:text-white shadow-lg hover:shadow-xl rounded-lg border px-3 py-1 text-xs lg:text-sm"
            id={x.id}
            onClick={() => {
              setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.map((j) => {
                  if (j.id === x.id) {
                    return {
                      ...j,
                      contactPassenger: true,
                    };
                  }
                  return j;
                }),
              });
            }}
          >
            {`Ajouter un moyen de contact pour ${x.firstName} ${x.lastName}`}
          </button>
        );
      })} */}
    </>
  );
}
