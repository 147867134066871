/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PaymentMethods from '../PaymentMethods';

export default function FormationSection({
  items,
}) {
  const [images, setImages] = useState(items.data?.ParaglidingItems?.course?.map((x) => ({
    id: x.id,
    count: Math.floor(Math.random() * (3 - 1) + 1),
  })));
  return (
    <>
      <div className="relative flex flex-col justify-center items-center bg-white w-full h-full px-3 lg:px-6 overflow-hidden text-gray-500">
        {/* Text */}
        <h2 className="text-xl lg:text-4xl font-playfair uppercase mb-3 text-center">
          Apprendre &agrave; faire du
          <span className="text-[#ceb071] px-2">
            Parapente
          </span>
        </h2>
        {/* Logos */}
        <div className="flex justify-center items-center gap-6 lg:gap-12 mb-6 lg:mb-12">
          <img
            src="/image/icons/casqueN1Gray.svg"
            alt="casque niveau 1"
            className="w-auto h-[20px] lg:h-[30px] animate-shake"
            loading="lazy"
          />
          <img
            src="/image/icons/casqueN20Gray.svg"
            alt="casque niveau 3"
            className="w-auto h-[20px] lg:h-[30px] animate-shake"
            loading="lazy"
          />
          <img
            src="/image/icons/casqueN2Gray.svg"
            alt="casque niveau 2"
            className="w-auto h-[20px] lg:h-[30px] animate-shake"
            loading="lazy"
          />
        </div>
        {/* Intro */}
        <div className="font-light mb-3 w-full lg:w-2/3 2xl:w-1/2 text-xs md:text-sm xl:text-base text-center text-balance">
          Apprendre &agrave; faire du parapente avec une &eacute;quipe
          de professionnels dipl&ocirc;m&eacute;s d&apos;&eacute;tats.
          Nous proposons des formations pour tous les niveaux avec du matériel de qualité
          et une pédagogie unique. Du stage d&apos;initiation sur 4 jours aux stages
          de perfectionnement.
          Vous serez accompagnés par des formateurs expérimentés et passionnés.
          Vous pourrez progrésser rapidement et en toute sécurité.
        </div>
        {/* Images */}
        <div className="hidden lg:block absolute lg:top-[280px] 2xl:top-[250px] lg:right-[170px] 2xl:right-[200px] w-auto lg:h-[130px] 2xl:h-[175px] group overflow-hidden rounded-lg shadow-lg z-10">
          <img
            alt="volez en parapente (tandem) 1"
            className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
            src="/image/homeImages/paragliding_vulcan_small.webp"
            loading="lazy"
          />
        </div>
        <div className="hidden lg:block absolute lg:top-[350px] 2xl:top-[360px] lg:right-[5px] 2xl:right-[20px] w-auto lg:h-[120px] 2xl:h-[165px] group overflow-hidden rounded-lg shadow-xl z-0">
          <img
            alt="volez et observez un baleine"
            className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
            src="/image/homeImages/paragliding_sunset_small.webp"
            loading="lazy"
          />
        </div>
        <div className="hidden lg:block absolute lg:top-[200px] 2xl:top-[150px] lg:right-[10px] 2xl:right-[35px] w-auto lg:h-[120px] 2xl:h-[160px] group overflow-hidden rounded-lg shadow-xl z-20">
          <img
            alt="volez en parapente (tandem) 1"
            className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
            src="/image/homeImages/paragliding_school_small.webp"
            loading="lazy"
          />
        </div>
        <div className="w-full lg:w-4/12 text-lg 2xl:text-2xl 2xl:w-4/12 font-playfair font-light text-center bg-clip-text text-transparent bg-gradient-to-tl from-gray-500 from-10% via-gray-400 via-40% to-gray-500 to-90% mt-10 lg:mt-40 2xl:mt-40 animate-text-bg-gradient">
          Réservez en ligne votre formation en toute tranquilité
          <br />
          Profitez de nos nombreux avantages
        </div>
        <div className="relative flex flex-wrap justify-center lg:justify-center items-center lg:items-stretch gap-4 w-full xl:max-w-screen-xl h-full text-gray-500 my-20">
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <div className="mb-2">
              <img
                src="/image/icons/icon-theorie-black.svg"
                alt="french flag"
                className="w-[20px] lg:w-[30px]"
              />
            </div>
            <div className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Un site dédié pour réserver vos formations et suivre votre progression
            </div>
          </div>
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <div className="flex gap-2 mb-3">
              <a
                href="https://federation.ffvl.fr/"
                className="w-[30px] lg:w-[30px]"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="qrt"
                  src="/image/certifications/logo-efvl.svg"
                />
              </a>
            </div>
            <div className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Une école certifiée EFVL avec des moniteurs passionés
            </div>
          </div>
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <div className="flex gap-2 mb-2">
              <img
                src="/image/icons/icon-simulateur.svg"
                alt="french flag"
                className="w-[30px] lg:w-[30px]"
                loading="lazy"
              />
              <img
                src="/image/icons/icon-protection.svg"
                alt="french flag"
                className="w-[30px] lg:w-[30px]"
                loading="lazy"
              />
              <img
                src="/image/icons/icon-reglementation.svg"
                alt="french flag"
                className="w-[30px] lg:w-[30px]"
                loading="lazy"
              />
            </div>
            <div className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Un simulateur et un équipement de haute qualité
            </div>
          </div>
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <i className="fas fa-credit-card lg:text-2xl mb-3 lg:mb-0" />
            <div className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Paiement en ligne rapide et sécurisé
            </div>
            <PaymentMethods size="small" />
          </div>
          <div className="flex flex-col justify-start items-center mx-3 lg:mx-0 py-3 w-full h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <img
              src="/image/icons/logo-mercedes-black.svg"
              alt="french flag"
              className="w-[60px]"
              loading="lazy"
            />
            <div className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Moyen de transport neuf, climatisé et confortable
            </div>
          </div>
          <div className="flex flex-col justify-start items-center mx-3 lg:mx-0 py-3 w-full h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <div className="flex gap-4 mb-3">
              <i className="fas fa-snowflake lg:text-2xl" />
              <i className="fas fa-restroom lg:text-2xl" />
              <i className="fa-solid fa-martini-glass-citrus lg:text-2xl" />
            </div>
            <div className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Batiment climatisé, avec toilettes privées et snacking / boissons
            </div>
          </div>
        </div>
        <div className="w-full lg:w-4/12 text-lg 2xl:text-2xl font-playfair font-light text-center text-slate-500 mb-12">
          Retrouvez certaines de nos formations ci-dessous
          ou créez dès maintenant votre compte sur
          <br />
          <a
            href="https://www.school.addictparapente.com"
            className="hover:text-sky-400 font-playfair"
          >
            school.addictparapente.com
          </a>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-stretch w-full h-full gap-4 px-6 lg:px-0">
        {items.data?.ParaglidingItems?.course?.filter((x) => x.positionLevel <= 3)?.map((x) => (
          <div key={x.id} className="relative flex flex-col justify-start w-full rounded-lg shadow-lg hover:shadow-xl bg-transparent group max-w-[450px]">
            <a
              href="https://school.addictparapente.com"
              className="flex flex-col w-full h-full bg-transparent"
              target="_blank"
              rel="noreferrer"
            >
              <div className="relative flex flex-col justify-center items-stretch flex-nowrap w-full text-zinc-500">
                {/* Picture */}
                <div className="relative mb-3">
                  {/* Picture control buttons */}
                  <button
                    type="button"
                    className="absolute right-2 top-1/2 -translate-y-1/2 group-hover:block lg:hidden bg-transparent px-3 lg:px-5 py-5 z-30"
                    aria-label="right"
                    onClick={() => setImages(images?.map((k) => {
                      if (k.id === x.id) {
                        if (k.count >= 2) return ({ id: k.id, count: 0 });
                        return ({ id: k.id, count: k.count + 1 });
                      }
                      return k;
                    }))}
                  >
                    <i className="fa-solid fa-circle-right text-xl text-white " />
                  </button>
                  <button
                    type="button"
                    className="absolute left-2 top-1/2 -translate-y-1/2 group-hover:block lg:hidden bg-transparent px-3 lg:px-5 py-5 z-30"
                    aria-label="left"
                    onClick={() => setImages(images?.map((k) => {
                      if (k.id === x.id) {
                        if (k.count === 0) return ({ id: k.id, count: 2 });
                        return ({ id: k.id, count: k.count - 1 });
                      }
                      return k;
                    }))}
                  >
                    <i className="fa-solid fa-circle-left text-xl text-white " />
                  </button>
                  <img
                    alt={`${x?.picturePath}`}
                    className="rounded-tl-lg rounded-tr-lg object-cover h-48 w-full"
                    src={`/image/paragliding/courses_small/level${x.positionLevel + 1}-${images?.find((k) => k.id === x.id)?.count}.webp`}
                    onError={({ currentTarget }) => {
                      const ct = currentTarget;
                      ct.onerror = null; // prevents looping
                      ct.src = `/image/paragliding/courses_small/level1-${Math.floor(Math.random() * (7 - 1) + 1)}.webp`;
                    }}
                    loading="lazy"
                  />
                </div>
                {/* Text zone */}
                <div className="flex flex-col w-full justify-center items-center mb-3">
                  <div className="flex justify-between items-center text-base words-break text-center overflow-hidden w-full px-2 lg:px-3 mb-3">
                    <h3 className="text-lg font-playfair font-semibold text-[#ceb071]">
                      {`${x?.name}`}
                    </h3>
                    <div className="font-bold tracking-tight uppercase text-xs lg:text-sm">
                      {`${x.price / 100}€ par séance`}
                    </div>
                  </div>
                  <div className="font-semibold tracking-normal text-gray-400 text-sm lg:text-sm group-hover:hidden">
                    {` ${x?.shortDesc}`}
                  </div>
                  <div className="font-semibold tracking-normal text-gray-400 text-sm lg:text-sm group-hover:block hidden">
                    Inscrivez-vous dès maintenant !
                  </div>
                  <hr className="w-1/2 my-3" />
                  <div className="flex flex-row justify-center items-center w-full text-xs mb-3">
                    <div className="flex flex-col justify-center items-center gap-2 w-1/3">
                      <i className="fas fa-clock mr-3" />
                      {`${x?.durationDays > 1 ? `${x?.durationDays} jours` : '1 jour'} de formation`}
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2 w-1/3">
                      <i className="fas fa-location-pin mr-3" />
                      {`à ${x?.activityArea}`}
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2 w-1/3">
                      <i className="fas fa-mountain-sun mr-3" />
                      {`${x?.altitude} mètres d'altitude`}
                    </div>
                  </div>
                  <div className="text-gray-400 text-sm lg:text-sm px-3 text-center">
                    {` ${x?.longDesc}`}
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
}
