/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <section className="bg-sky-500 w-full p-6 text-white">
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 w-full text-xs">
        <div className="grid grid-rows-2 gap-8">
          <div>
            <a href={`${window.location.origin}`} className="text-xl font-semibold mb-3">
              Addict Parapente
            </a>
            <p className="mt-3">
              Structure unique et lab&eacute;lis&eacute;e pour faire
              des vols, bapt&ecirc;mes, tandems et se former au parapente sur l&apos;&icirc;le
              de la R&eacute;union &agrave; Saint-Leu.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-3">
              Addict c&apos;est aussi
            </h3>
            <ul>
              <li className="mb-3">
                <Link
                  to="https://addict-burger.com/"
                >
                  https://addict-burger.com/ - Pour vous restaurer sur place
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="https://voilerie-reunion.com"
                >
                  https://voilerie-reunion.com - Pour acheter/r&eacute;parer des voiles
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="grid grid-rows-2 gap-8">
          <div>
            <a href={`${window.location.origin}/reserver/bapteme`} className="text-xl font-semibold">
              Addict Parapente bapt&ecirc;mes et tandems
            </a>
            <ul>
              <li className="mt-3 mb-3">
                <a
                  href={`${window.location.origin}/reserver/bapteme#biplace-marmay`}
                >
                  Marmay - parapente pour les enfants (7 &agrave; 12 ans)
                </a>
              </li>
              <li className="mb-3">
                <a
                  href={`${window.location.origin}/reserver/bapteme#biplace-decouverte`}
                >
                  D&eacute;couverte - Faire un vol en parapente &agrave; 800m d&apos;altitude
                </a>
              </li>
              <li className="mb-3">
                <a
                  href={`${window.location.origin}/reserver/bapteme#biplace-grande-decouverte`}
                >
                  Grande D&eacute;couverte - Faire un vol en parapente &agrave; 1600m
                  d&apos;altitude
                </a>
              </li>
              <li className="mb-3">
                <a
                  href={`${window.location.origin}/reserver/bapteme#biplace-acrobatic`}
                >
                  Acrobatique - Faire un vol en parapente sensationnel
                </a>
              </li>
            </ul>
          </div>
          <div>
            <a href="https://www.school.addictparapente.com" className="text-xl font-semibold mb-3">
              Addict Parapente Formations
            </a>
            <ul>
              <li className="mt-3 mb-3">
                <a
                  href="https://www.school.addictparapente.com/view/debutant"
                >
                  D&eacute;butant - D&eacute;buter le parapente, apprendre les bases
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.school.addictparapente.com/view/primo_vol"
                >
                  Primo Vol - Faire son premier vol, seul, encadr&eacute;
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.school.addictparapente.com/view/dune_du_pilat"
                >
                  Dune du Pilat - D&eacute;couvrir le vol dynamique
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://www.school.addictparapente.com/view/fly_senteur"
                >
                  Fly Senteur - Perfectionnement sur l&apos;&icirc;le de la R&eacute;union
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="grid grid-rows-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-3">
              Retrouvez nous sur
            </h3>
            <div className="flex flex-row justify-start items-center w-full text-3xl">
              <a className="w-1/3 py-4" href="https://www.facebook.com/Addict.Parapente/" aria-label="facebook">
                <i className="fa-brands fa-facebook-f" />
              </a>
              <a className="w-1/3 py-4" href="https://twitter.com/addict_sx" aria-label="x">
                <i className="fa-brands fa-x-twitter" />
              </a>
              <a className="w-1/3 py-4" href="https://www.youtube.com/channel/UCnq5iikYEYOqfiBFzqSVrZg" aria-label="youtube">
                <i className="fa-brands fa-youtube" />
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-3">
              Contactez nous
            </h3>
            <div className="flex flex-col w-full text-xs">
              <a className="mb-3" href="tel:(0262) 692-256363">
                <i className="fas fa-phone mx-1" />
                (0262) 692-256363
              </a>
              <a className="" href="mailto:info@addictparapente.com">
                <i className="fas fa-at mx-1" />
                info@addictparapente.com
              </a>
            </div>
          </div>
        </div>
        <div className="grid grid-rows-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-3">
              Nous trouver
            </h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.21634198042!2d55.276036999999995!3d-21.143787000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2182972f360fda8f%3A0x7ca2366aa1e81d8b!2s5%20Rue%20des%20Ebenistes%2C%20Saint-Leu%2097436%2C%20La%20R%C3%A9union!5e0!3m2!1sfr!2sfr!4v1715842421062!5m2!1sfr!2sfr"
              className="w-full h-[100px] block"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Maps"
            />
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-3">
              Horaires
            </h3>
            <ul>
              <li>
                LUNDI 07:00 AM - 16:00 PM
              </li>
              <li>
                MARDI 07:00 AM - 16:00 PM
              </li>
              <li>
                MERCREDI 07:00 AM - 16:00 PM
              </li>
              <li>
                JEUDI 07:00 AM - 16:00 PM
              </li>
              <li>
                VENDREDI 07:00 AM - 16:00 PM
              </li>
              <li>
                SAMEDI 07:00 AM - 12:00 PM
              </li>
              <li>
                DIMANCHE 07:00 AM - 12:00 PM
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
