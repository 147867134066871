/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import CheckPassengerIdentity from './CheckPassengerIdentity';

export default function PassengerForm({
  cart,
  setCart,
  tandemOrderItem,
  itemDetails,
  nameFormat,
  setError,
  orderIdx,
  setOrderIdx,
  passengerRefs,
  paymentRef,
  setShowCompletedOrderItem,
}) {
  const [isValidIdentity, setIsValidIdentity] = useState(false);
  const [isValidFirstName, setIsValidFirstName] = useState(false);
  const [isValidLastName, setIsValidLastName] = useState(false);
  const [showSpecialCondition, setShowSpecialCondition] = useState(false);
  const updateOrderItem = () => {
    fetch(`${process.env.REACT_APP_API_URL}/public/paragliding/tandem/order/update`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        stripePaymentIntentID: cart.stripePaymentIntentID,
        tandemOrderItem,
      }),
    })
      .then((r) => r.json())
      .then((json) => {
        if (!json.error) {
          setCart({
            ...cart,
            tandemOrderItems: cart.tandemOrderItems.map((x) => {
              if (x.id === json.id) {
                return {
                  ...json,
                  isSaved: true,
                };
              }
              return x;
            }),
          });
          if ((orderIdx < (cart.tandemOrderItems.length - 1))) {
            passengerRefs.current[orderIdx + 1].current.scrollIntoView({ behavior: 'smooth' });
            setOrderIdx(orderIdx + 1);
          }
          if ((orderIdx === cart.tandemOrderItems.length - 1)) {
            paymentRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          setShowCompletedOrderItem(true);
        } else {
          const error = new Error('error');
          error.msg = json.error;
          throw error;
        }
      })
      .catch((err) => {
        setError(err);
      });
  };
  useEffect(() => {
    if (itemDetails?.name) {
      if (itemDetails.name === 'Grande découverte') {
        setShowSpecialCondition(true);
      } else {
        setCart({
          ...cart,
          tandemOrderItems: cart.tandemOrderItems.map((j) => {
            if (j.id === tandemOrderItem.id) {
              return {
                ...j,
                isPassengerAbleToRun: true,
              };
            }
            return j;
          }),
        });
      }
    }
  }, [itemDetails.name]);
  return (
    <>
      <h3 className="text-sm lg:text-xl uppercase text-left mr-3 w-full">
        Identitée du passager
      </h3>
      <div className="flex flex-col lg:flex-row justify-center items-center mb-3 w-full lg:gap-2">
        <label htmlFor={`firstName_${tandemOrderItem.id}`} className="flex flex-col justify-center w-full lg:w-full mb-6">
          <p className="text-sm">
            Prénom
          </p>
          <div className="flex items-center shadow rounded">
            <input
              type="text"
              id={`firstName_${tandemOrderItem.id}`}
              className="appearance-none outline-none px-3 py-3 w-full rounded"
              value={tandemOrderItem?.firstName || ''}
              onChange={(e) => setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.map((j) => {
                  if (j.id === tandemOrderItem.id) {
                    return {
                      ...j,
                      firstName: e?.target?.value,
                    };
                  }
                  return j;
                }),
              })}
            />
            {(isValidFirstName)
              && <i className="mx-2 inline text-green-400 fa-solid fa-check" />}
          </div>
        </label>
        <label htmlFor={`lastName_${tandemOrderItem.id}`} className="flex flex-col justify-center w-full lg:w-full mb-6">
          <p className="text-sm">
            Nom
          </p>
          <div className="flex items-center shadow rounded">
            <input
              type="text"
              id={`lastName_${tandemOrderItem.id}`}
              className="appearance-none outline-none px-3 py-3 w-full rounded"
              value={tandemOrderItem?.lastName || ''}
              onChange={(e) => setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.map((j) => {
                  if (j.id === tandemOrderItem.id) {
                    return {
                      ...j,
                      lastName: e?.target?.value,
                    };
                  }
                  return j;
                }),
              })}
            />
            {(isValidLastName)
              && <i className="mx-2 inline text-green-400 fa-solid fa-check" />}
          </div>
        </label>
      </div>
      {!itemDetails.isPhotosIncluded
        && (
          <>
            <h3 className="text-sm lg:text-xl uppercase text-left mr-3 w-full">
              Options
            </h3>
            <label
              htmlFor={`photos_${tandemOrderItem.id}`}
              className={`flex mx-2 justify-left items-center lg:w-full mb-6 lg:mb-0
              ${tandemOrderItem.photos
                ? ' text-zinc-900'
                : ' text-zinc-500'}`}
            >
              <input
                type="checkbox"
                id={`photos_${tandemOrderItem.id}`}
                className="relative peer shrink-0
                appearance-none w-8 h-8 border-2 border-blue-500 rounded-full
                mt-1
                checked:border-1"
                checked={tandemOrderItem?.wantPhotos}
                disabled
                onChange={(e) => {
                  const newCart = {
                    ...cart,
                    tandemOrderItems: cart.tandemOrderItems.map((j) => {
                      if (j.id === tandemOrderItem.id) {
                        return {
                          ...j,
                          wantPhotos: e?.target?.checked,
                        };
                      }
                      return j;
                    }),
                    total: e.target.checked
                      ? cart.total + tandemOrderItem.photosPrice
                      : cart.total - tandemOrderItem.photosPrice,
                  };
                  setCart(newCart);
                }}
              />
              <p className="mx-2">
                {`Album photos & vidéos (+${tandemOrderItem.photosPrice}€)`}
              </p>
              <i className="fas fa-camera absolute w-8 h-8 mt-2 ml-[7px] text-lg hidden peer-checked:block text-sky-700 pointer-events-none" />
            </label>
          </>
        )}
      {showSpecialCondition
        && (
          <div className="text-xs lg:text-sm">
            <p className="text-sm lg:text-xl uppercase text-left mr-3 w-full">
              Condition spécifique
            </p>
            <p className="text-xs text-gray-400 mb-3">
              Le vol Grande découverte nécessite que le passager soit endurant et sportif
              car le décollage demande plus d&apos;efforts.
              <br />
              Si ce n&apos;est pas le cas, veuillez séléctionné un autre
              type de vol
            </p>
            <label htmlFor="isPassengerAbleToRun" className="flex justify-start items-center pl-3 mb-3 w-full">
              <input
                id="isPassengerAbleToRun"
                type="checkbox"
                className="block mr-3"
                checked={tandemOrderItem?.isPassengerAbleToRun}
                onChange={(e) => {
                  setCart({
                    ...cart,
                    tandemOrderItems: cart.tandemOrderItems.map((j) => {
                      if (j.id === tandemOrderItem.id) {
                        return {
                          ...j,
                          isPassengerAbleToRun: e?.target?.checked,
                        };
                      }
                      return j;
                    }),
                  });
                }}
              />
              En cochant vous confirmez que ce passager a une bonne endurance et est sportif
            </label>
          </div>
        )}
      <CheckPassengerIdentity
        tandemOrderItem={tandemOrderItem}
        itemDetails={itemDetails}
        isValidIdentity={isValidIdentity}
        setIsValidIdentity={setIsValidIdentity}
        setIsValidFirstName={setIsValidFirstName}
        setIsValidLastName={setIsValidLastName}
        nameFormat={nameFormat}
      />
      {isValidIdentity
        && (

          <button
            type="button"
            className="bg-sky-400 text-white rounded-lg py-2 shadow-lg hover:shadow-xl w-full lg:w-1/2 uppercase text-xs lg:text-sm font-bold"
            onClick={() => {
              setShowCompletedOrderItem(true);
              updateOrderItem();
            }}
          >
            Confirmer
          </button>
        )}
    </>
  );
}
