/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

export default function CheckPassengerAgeAndWeight({
  tandemOrderItem,
  itemDetails,
  isValidAgeAndWeight,
  setIsValidAgeAndWeight,
  setIsValidAge,
  setIsValidWeight,
  ageAndWeightRequirements,
}) {
  const [content, setContent] = useState('');
  const [animate, setAnimate] = useState(false);
  const [warn, setWarn] = useState(false);
  useEffect(() => {
    setAnimate(true);
    if (!tandemOrderItem?.age) {
      setIsValidAge(false);
      setIsValidAgeAndWeight(false);
      setWarn(false);
      setContent('Renseignez l\'âge et le poids de ce passager afin de pré-séléctionner des dates optimales pour ce vol');
      return;
    }
    if (itemDetails.name !== 'Marmay' && (tandemOrderItem?.age < ageAndWeightRequirements.minAdultAge || tandemOrderItem?.age > ageAndWeightRequirements.maxAdultAge)) {
      setIsValidAge(false);
      setIsValidAgeAndWeight(false);
      setWarn(true);
      setContent(`Ce vol est limité aux adultes de ${ageAndWeightRequirements.minAdultAge} à ${ageAndWeightRequirements.maxAdultAge} ans`);
      return;
    }
    if (itemDetails.name === 'Marmay' && (tandemOrderItem?.age < ageAndWeightRequirements.minMarmayAge || tandemOrderItem?.age > ageAndWeightRequirements.maxMarmayAge)) {
      setIsValidAge(false);
      setIsValidAgeAndWeight(false);
      setWarn(true);
      setContent(`Ce vol est limité aux enfants de ${ageAndWeightRequirements.minMarmayAge} à ${ageAndWeightRequirements.maxMarmayAge} ans`);
      return;
    }
    setIsValidAge(true);
    if (!tandemOrderItem?.weight) {
      setIsValidWeight(false);
      setIsValidAgeAndWeight(false);
      setWarn(false);
      setContent('Renseignez le poids de ce passager');
      return;
    }
    if (itemDetails.name !== 'Marmay' && (tandemOrderItem?.weight < ageAndWeightRequirements.minAdultWeight || tandemOrderItem?.weight > ageAndWeightRequirements.maxAdultWeight)) {
      setIsValidWeight(false);
      setIsValidAgeAndWeight(false);
      setWarn(true);
      setContent(`Ce vol est limité aux personnes ayant un poids compris entre ${ageAndWeightRequirements.minAdultWeight}kg et ${ageAndWeightRequirements.maxAdultWeight}kg`);
      return;
    }
    if (itemDetails.name === 'Marmay' && (tandemOrderItem?.weight < ageAndWeightRequirements.minMarmayWeight || tandemOrderItem?.weight > ageAndWeightRequirements.maxMarmayWeight)) {
      setIsValidWeight(false);
      setIsValidAgeAndWeight(false);
      setWarn(true);
      setContent(`Ce vol est limité aux enfants ayant un poids compris entre ${ageAndWeightRequirements.minMarmayWeight}kg et ${ageAndWeightRequirements.maxMarmayWeight}kg`);
      return;
    }
    setWarn(false);
    setIsValidWeight(true);
    setIsValidAgeAndWeight(true);
  }, [tandemOrderItem]);

  if (!isValidAgeAndWeight) {
    return (
      <div className={`relative w-auto h-auto mb-3 bg-sky-400 rounded-xl shadow-lg text-white ${animate ? 'animate-fade' : ''}`} onAnimationEnd={() => setAnimate(false)}>
        <div className="absolute left-2 top-1/2 -translate-y-1/2 rounded-full text-sky-400 w-6 h-6 text-center">
          <i className={`fas ${warn ? 'fa-warning' : 'fa-circle-question'} text-lg text-white font-bold`} />
        </div>
        <div className=" w-full px-12 py-3  flex justify-center items-center">
          {content}
        </div>
      </div>
    );
  }
}
