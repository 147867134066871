import * as ReactDOM from 'react-dom/client';
import React from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
);
reportWebVitals();
