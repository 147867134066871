/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ModalError({
  error,
  setError,
}) {
  let msg = 'Erreur inconnue...';
  let actionOnClose = 'beginning';
  if (error === 'expired') {
    msg = 'Votre session à expirée. Veuillez recommencer';
  }
  if (error === 'event is full') {
    msg = 'Votre session à expirée. Veuillez recommencer';
    actionOnClose = 'close';
  }
  const navigate = useNavigate();
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl px-3 lg:px-0">
          <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex flex-col justify-center items-center p-5 text-gray-500">
              <h3 className="text-sm lg:text-xl">
                Une erreur est survenue !
              </h3>
              <div className="text-base lg:text-lg relative p-3 lg:p-6 text-center">
                {msg}
              </div>
              <button
                type="button"
                onClick={() => {
                  setError('');
                  if (actionOnClose === 'beginning') {
                    navigate(0);
                  }
                }}
                className="w-full border shadow-lg hover:shadow-xl h-10 rounded-lg px-1"
              >
                Recommencer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-45 fixed inset-0 z-[45] bg-black" />
    </>
  );
}
