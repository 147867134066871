/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';

import CheckoutForm from './checkoutForm';

function Payment({
  stripePromise,
  cart,
  setCart,
  setError,
  letUserToEnterPromoOrCashbackCode,
}) {
  const mailFormat = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const [showCode, setShowCode] = useState(false);
  const [isCodeCheckLoading, setIsCodeCheckLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const codeValidity = () => {
    if (cart?.code && cart?.code?.length > 0) {
      setIsCodeCheckLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/public/paragliding/tandem/events/pre_order/code?code=${cart?.code}&stripe_payment_intent_id=${cart.stripePaymentIntentID}&order_id=${cart.id}&email=${cart.email}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((json) => {
          setIsCodeSent(true);
          setIsCodeCheckLoading(false);
          if (!json.error && json?.codeID) {
            setIsCodeValid(true);
            setCart({
              ...cart,
              codeID: json.codeID,
              oldTotal: json.oldTotal,
              grandTotal: json.grandTotal,
              cashbackAmountLeft: json.cashbackAmountLeft,
              cashbackValue: json.cashbackValue,
              discountPercent: json.discountPercent,
            });
            return;
          }
          if (json.error === 'bad code') {
            setIsCodeValid(false);
            return;
          }
          const error = new Error('error');
          error.msg = json.error;
          throw error;
        })
        .catch((error) => {
          setIsCodeSent(true);
          setIsCodeCheckLoading(false);
          setError(error.msg);
        });
    }
  };
  return (
    <div className="w-full">
      {letUserToEnterPromoOrCashbackCode
        && (
          <>
            <hr className="lg:hidden w-full my-6" />
            <h3 className="text-sm lg:text-lg uppercase text-left mr-3 mb-3">
              Code promotionnel, carte cadeau ou avoir
            </h3>
            <button
              type="button"
              className={`${cart?.codeID ? 'bg-white' : ' text-sky-600 hover:text-sky-500'} text-sm uppercase text-left mr-3 mb-3 w-full`}
              onClick={() => setShowCode(!showCode)}
            >
              <i className="fas fa-gift mr-3 pl-3" />
              J&apos;ai un code Promotion ou un Avoir ?
            </button>
            {(showCode && (cart?.phone
              && (isValidPhoneNumber(cart?.phone) && isPossiblePhoneNumber(cart?.phone)))
              && (cart?.email && cart?.email.match(mailFormat) !== null))
              && (
                <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-start mb-6 w-full lg:gap-2">
                  <label htmlFor="code" className="flex flex-col mb-3 lg:mb-0 w-full lg:w-44">
                    <p className="text-base">
                      Votre code
                    </p>
                    <div className="flex items-center shadow rounded w-full">
                      <input
                        type="text"
                        id="code"
                        className="appearance-none outline-none px-3 py-3 w-full rounded"
                        value={cart.code || ''}
                        onChange={(e) => {
                          setCart({
                            ...cart,
                            code: e?.target?.value,
                          });
                        }}
                      />
                    </div>
                  </label>
                  <button
                    type="button"
                    className={`${(cart?.code && cart?.code?.length > 0) ? 'block' : 'hidden'} ${cart?.codeID ? 'bg-emerald-400 text-white' : 'bg-sky-700 hover:bg-sky-600 text-white'} shadow-lg rounded-lg lg:mt-5 w-32 h-10`}
                    onClick={() => codeValidity()}
                    disabled={!!cart?.codeID}
                  >
                    {(!isCodeCheckLoading && !isCodeValid && !cart?.codeID)
                      && 'Vérifier'}
                    {(!isCodeCheckLoading && isCodeValid && cart?.codeID)
                      && <i className="fas fa-check" />}
                    {(isCodeCheckLoading)
                      && <i className="fas fa-spinner animate-spin" />}
                  </button>
                </div>
              )}
            {(showCode && (!(cart?.phone
              && (isValidPhoneNumber(cart?.phone) && isPossiblePhoneNumber(cart?.phone)))
              || !(cart?.email && cart?.email.match(mailFormat) !== null)))
              && (
                <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-start mb-6 w-full lg:gap-2">
                  <div className="flex flex-col mb-3 lg:mb-0 w-full animate-shake">
                    Vous devez renseigner votre numéro de téléphone et votre e-mail avant
                  </div>
                </div>
              )}
            {(!isCodeCheckLoading && isCodeValid && !!cart?.codeID && !!cart?.cashbackAmountLeft)
              && (
                <p className="animate-shake text-sky-500 mb-3">
                  {`Après le réglement vous recevrez un e-mail avec un nouveau code pour utiliser les ${cart.cashbackAmountLeft / 100}€
                  restant`}
                </p>
              )}
            {(!isCodeCheckLoading && isCodeSent && !isCodeValid)
              && (
                <p className="animate-shake text-rose-500 mb-3">
                  Le code n&apos;est pas bon !
                </p>
              )}
            {(!isCodeCheckLoading && isCodeSent && isCodeValid && cart?.discountPercent > 0)
              && (
                <p className="animate-shake text-emerald-500 mb-3">
                  {`Validé ! Vous avez une réduction de ${cart?.discountPercent}%`}
                </p>
              )}
            {(!isCodeCheckLoading && isCodeSent && isCodeValid && cart?.cashbackValue > 0)
              && (
                <p className="animate-shake text-emerald-500 mb-3">
                  {`Validé ! Vous avez une réduction de ${cart.cashbackValue / 100}€`}
                </p>
              )}
          </>
        )}
      <hr className="lg:hidden w-full my-6" />
      <h3 className="text-sm lg:text-lg uppercase text-left mr-3 mb-3">
        Paiement
      </h3>
      {(cart.stripePaymentIntentClientSecret && stripePromise)
        && (
          <CheckoutForm
            cart={cart}
            setError={setError}
          />
        )}
    </div>
  );
}

export default Payment;
