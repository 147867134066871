/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

function CheckoutForm({
  cart,
  setError,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const stripeConfirmPayment = async () => {
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment_status`,
      },
    });

    setIsProcessing(false);
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    let url = `${process.env.REACT_APP_API_URL}/public/paragliding/tandem/events/pay_order`;
    if (cart.isAGift) {
      url = `${process.env.REACT_APP_API_URL}/public/paragliding/gift/order/pay`;
    }
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(cart),
    })
      .then((r) => r.json())
      .then((json) => {
        if (!json.error) {
          if (cart.grandTotal > 0) {
            stripeConfirmPayment();
          } else {
            const pi = `payment_intent=${cart.stripePaymentIntentID}`;
            navigate(`/payment_status?${pi}&redirect_status=succeeded&free=true`, { replace: true });
          }
          return;
        }
        if (json.error) {
          const error = new Error('error');
          error.msg = json.error;
          throw error;
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(err.msg);
        return err.msg;
      });
  };

  return (
    <form id="payment-element" onSubmit={handleSubmit}>
      {cart?.grandTotal > 0
        && (
          <PaymentElement id="payment-element" />
        )}
      {message
        && (
          <div className="mt-6 text-lg text-gray-500 font-semibold text-center" id="payment-message">
            <p>
              Impossible de procéder au paiement pour la raison suivante:
              <br />
              {message}
            </p>
          </div>
        )}
      <div className="mt-12 flex gap-2">
        <button
          type="submit"
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="rounded uppercase group-hover:font-semibold px-3 py-3 font-normal w-full hover:shadow-lg bg-sky-400 text-white"
        >
          {(isProcessing && cart.grandTotal > 0)
            && 'Patientez, paiement en cours...'}
          {(!isProcessing && cart.grandTotal > 0)
            && `Payer ${cart.grandTotal / 100}€`}
          {(!isProcessing && cart.grandTotal === 0)
            && 'Valider'}
          {(isProcessing && cart.grandTotal === 0)
            && 'Patientez, validation en cours...'}
        </button>
      </div>
    </form>
  );
}

export default CheckoutForm;
