/* eslint-disable react/prop-types */
import React from 'react';

export default function ActivityRequirements({
  setModal,
}) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl px-3 lg:px-0">
          <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex flex-col justify-center items-center p-5 text-gray-500">
              <h3 className="text-sm lg:text-xl mb-6">
                La pratique du parapente est interdite aux personnes suivantes
              </h3>
              <div className="pl-3">
                <p className="mb-3 w-full text-xs">
                  Personnes n&apos;étant pas capable de courir lors du décollage
                </p>
                <p className="mb-3 w-full text-xs">
                  Femmes enceintes
                </p>
                <p className="mb-3 w-full text-xs">
                  Personnes ayant une blessure invalidante
                  (luxation du genoux, cheville fragilisée..)
                </p>
                <p className="mb-3 w-full text-xs">
                  Personnes portant un platre
                </p>
                <p className="mb-3 w-full text-xs">
                  Personnes au-delà de 90kg
                </p>
                <p className="mb-3 w-full text-xs">
                  Personnes ayant des antécédents médicaux tels que des problèmes dorsaux, cervicaux
                  ou cardiaques sans certificat médical autorisant
                  expressément la pratique de l&apos;activité
                </p>
                <p className="mb-3 w-full text-xs">
                  Personnes à qui l&apos;activité physique est déconseillée
                </p>
                <p className="mb-3 w-full text-xs">
                  Enfants de moins de 5 ans
                </p>
                <p className="mb-3 w-full text-xs">
                  Mineurs de moins de 18 ans sans l&apos;autorisation
                  signée d&apos;un parent ou tuteur
                </p>
                <p className="mb-3 w-full text-xs">
                  Personnes sous influence d&apos;alcool ou de n&apos;importe quel autre drogues
                </p>
                <p className="text-xs text-gray-400 font-black mb-3">
                  Mention spéciale pour les personnes en situation de handicap:
                  Vous devez réserver par e-mail ou téléphone afin de
                  déterminer ensemble si l&apos;handicap
                  est compatible avec la pratique
                </p>
              </div>
              <button
                type="button"
                onClick={() => {
                  setModal(false);
                }}
                className="w-full border shadow h-10 rounded-lg px-1"
              >
                fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-45 fixed inset-0 z-40 bg-black" />
    </>
  );
}
