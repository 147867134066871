/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ActivityRequirements from '../ActivityRequirements';

export default function Policy({
  cart,
  setCart,
  setShowPayment,
}) {
  const [showActivityRequirements, setShowActivityRequirements] = useState(false);
  return (
    <>
      <div className="text-sm lg:text-lg uppercase text-left mr-3 w-full">
        Votre sécurité est notre priorité
        <p className="text-xs text-gray-400 mb-3 normal-case">
          Le parapente est une activitée sûre à condition de respecter certaine conditions.
        </p>
      </div>
      <button
        type="button"
        className="bg-white hover:bg-sky-400 text-sky-400 hover:text-white shadow-lg hover:shadow-xl px-3 py-2 border rounded-lg w-96 text-xs mb-6"
        onClick={() => setShowActivityRequirements(!showActivityRequirements)}
      >
        Consultez les conditions pour pratiquer le vol en tandem
      </button>
      {showActivityRequirements
        && (
          <ActivityRequirements setModal={setShowActivityRequirements} />
        )}
      <label htmlFor="isCGUAccepted" className="flex justify-start items-center pl-3 mb-3 w-full">
        <input
          id="isCGUAccepted"
          type="checkbox"
          className="block mr-3"
          checked={cart.isCGUAccepted}
          onChange={(e) => {
            setCart({
              ...cart,
              isCGUAccepted: e?.target?.checked,
              isCGVAccepted: e?.target?.checked,
            });
            if (!e?.target?.checked) setShowPayment(false);
          }}
        />
        En cochant vous confirmez que le ou les passagers ne sont pas concernés par l&apos;une
        des restrictions décrites dans nos conditions
      </label>
      <p className="text-xs lg:text-sm uppercase text-center w-full mb-3 text-balance">
        Attention le non-respect des conditions décrites
        ci-dessus entrainera automatiquement l&apos;annulation du vol et ce sans remboursement
      </p>
      {/* <p className="text-sm text-gray-400 mb-3 text-balance w-full text-center">
        Un doute ?
        <br />
        N&apos;hésitez pas à nous contacter
        <a href="tel:(0262)692256363" className="px-1">(0262)692256363</a>
        ou par e-mail à l&apos;adresse suivante:
        <a href="mailto:info@addictparapente.com" className="px-1">info@addictparapente.com</a>
      </p> */}
    </>
  );
}
