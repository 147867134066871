/* eslint-disable react/prop-types */
import React, {
  useRef,
  useState,
} from 'react';

import {
  Elements,
} from '@stripe/react-stripe-js';

import Navbar from '../components/navigation/Navbar';
import NavUpBtn from '../components/navigation/NavUpBtn';
import FixedCartBtn from '../components/navigation/FixedCartBtn';
import Footer from '../components/navigation/Footer';
import Order from '../components/biplace/Order';
import useScroll from '../components/useScroll';
import ModalError from '../components/ModalError';
import TandemSection from '../components/home/TandemSection';

export default function BookBiplace({
  stripePromise,
  cart,
  setCart,
  items,
  isPILoading,
  purchaseStep,
  setPurchaseStep,
  eventsDetails,
  setEventsDetails,
  error,
  setError,
  AddOrDeleteOrderItem,
}) {
  const [dates, setDates] = useState([]);
  const { isScroll } = useScroll();
  const ref = useRef();
  return (
    <main ref={ref} className="w-full font-montserrat">
      {(error !== '')
        && (
          <ModalError
            error={error}
            setError={setError}
          />
        )}
      <Navbar
        cart={cart}
        purchaseStep={purchaseStep}
        setPurchaseStep={setPurchaseStep}
        isScroll={isScroll}
        items={items}
      />
      {(isScroll && (!cart?.total || cart?.total === 0))
        && (
          <NavUpBtn />
        )}
      {(cart?.total > 0 && purchaseStep === 0)
        && (
          <FixedCartBtn
            cart={cart}
            setPurchaseStep={setPurchaseStep}
            items={items}
          />
        )}
      {(purchaseStep === 0)
        && (
          <section className="relative bg-white w-full mb-20">
            <div className="w-full h-44 lg:h-80 mb-20">
              <img
                alt="status_image"
                className="object-cover w-full h-full"
                src="/image/slide-1.jpg"
              />
            </div>
            <TandemSection
              cart={cart}
              items={items}
              AddOrDeleteOrderItem={AddOrDeleteOrderItem}
              isPILoading={isPILoading}
            />
          </section>
        )}
      {(purchaseStep >= 1 && !isPILoading && cart.stripePaymentIntentClientSecret !== '' && !items.isLoading)
        && (
          <section>
            <Elements
              mode="payment"
              stripe={stripePromise}
              options={{ clientSecret: cart.stripePaymentIntentClientSecret }}
            >
              <Order
                cart={cart}
                setCart={setCart}
                dates={dates}
                setDates={setDates}
                purchaseStep={purchaseStep}
                setPurchaseStep={setPurchaseStep}
                stripePromise={stripePromise}
                setError={setError}
                items={items.data?.ParaglidingItems?.tandem}
                eventsDetails={eventsDetails}
                setEventsDetails={setEventsDetails}
              />
            </Elements>
          </section>
        )}
      {(purchaseStep === 1 && isPILoading)
        && (
          <section>
            <div className="bg-gradient-to-tr from-zinc-500 to-gray-800 w-full h-80 -mb-60 lg:-mb-60" />
            <div className="flex items-stretch w-full lg:h-[650px] px-3 lg:px-6 pt-6 lg:pt-28 mb-16">
              <div className="flex flex-col justify-start shadow-lg w-full h-full rounded-lg bg-white">
                <div className="flex flex-col lg:flex-row animate-pulse gap-2 h-full">
                  <div className="lex justify-center items-center text-white w-full lg:w-1/3 h-1/3 lg:h-full lg:px-3 lg:py-28">
                    <div className="flex justify-center items-center text-white rounded-t-lg lg:rounded-lg bg-slate-700 w-full h-60 lg:h-full">
                      <i className="fa-solid fa-spinner animate-spin text-2xl mr-3" />
                      Chargement... Patientez svp
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-3 gap-y-1 w-full lg:w-1/3 py-28">
                    <div className="rounded bg-slate-700 mt-3 h-8 col-span-2" />
                    <div className="rounded bg-slate-700 h-4" />
                    <div />
                    <div className="rounded bg-slate-700 h-8 col-span-2" />
                    <div className="rounded bg-slate-700 h-20 col-span-2 flex justify-center items-center text-white">
                      <i className="fa-solid fa-spinner animate-spin text-2xl mr-3" />
                      Chargement... Patientez svp
                    </div>
                    <div className="rounded bg-slate-700 h-8 col-span-2" />
                    <div className="rounded bg-slate-700 h-4" />
                    <div />
                    <div className="rounded bg-slate-700 h-8 col-span-2" />
                    <div />
                  </div>
                  <div className="lex justify-center items-center text-white w-full lg:w-1/3 h-1/3 lg:h-full lg:px-3 lg:py-28">
                    <div className="flex justify-center items-center text-white rounded-b-lg lg:rounded-lg bg-slate-700 w-full h-60 lg:h-full">
                      <i className="fa-solid fa-spinner animate-spin text-2xl mr-3" />
                      Chargement... Patientez svp
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      <Footer />
    </main>
  );
}
