/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';

const baseURL = process.env.REACT_APP_API_URL;

export default function ReSchedule() {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [orders, setOrders] = useState({});
  const [error, setError] = useState('');
  const [preferedAction, setPreferedAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id && token) {
      setIsLoading(true);
      fetch(`${baseURL}/public/paragliding_orders_details?id=${id}&token=${token}${preferedAction !== null ? `&action=${preferedAction}` : ''}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      })
        .then((r) => r.json())
        .then((json) => {
          if (!json.error) {
            if (preferedAction === null) {
              setOrders(json);
            }
            setIsLoading(false);
            return;
          }
          setError(json.error);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [id, token, preferedAction]);
  if (id && token) {
    return (
      <main className="w-full font-montserrat text-gray-600 text-xs lg:text-sm">
        <Navbar
          cart={{ grandTotal: 0 }}
          isScroll={false}
        />
        <div className="bg-gradient-to-tr from-zinc-500 to-gray-800 w-full h-80 -mb-56" />
        <section className="flex items-center justify-center w-full lg:w-full p-6">
          <div className="flex flex-col items-center justify-center w-full h-full max-w-[1000px] mx-auto mb-24">
            {(!isLoading && preferedAction === null && error === '')
              && (
                <div className="flex flex-col justify-center items-center text-gray-500 bg-white rounded-lg w-full p-6 shadow">
                  <h1 className="text-xl text-center mb-12">
                    Comment souhaitez-vous procéder ?
                  </h1>
                  {(orders.stripeProposedAmountCashback > orders.stripeProposedAmountRefund)
                    && (
                      <>
                        <i className="fas fa-thumbs-up mb-3 text-xl" />
                        <h2 className="text-lg text-center mb-12">
                          Choisissez le bon d'achat pour récupérer la valeur de la promotion
                          que vous aviez utilisé !
                        </h2>
                      </>
                    )}
                  <div className="flex flex-col lg:flex-row justify-center items-center w-full h-full gap-2 px-3">
                    <button
                      type="submit"
                      className="flex flex-col justify-center items-center text-center text-sm bg-white w-full lg:w-1/2 min-h-44 shadow hover:shadow-lg outline-none focus:outline-none rounded-lg p-3"
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => setPreferedAction('cashback')}
                    >
                      <img
                        src="/image/icons/tickets-blue.svg"
                        alt="tickets"
                        className="w-auto h-[20px] lg:h-[30px] mb-3 animate-shake"
                      />
                      Recevoir un bon d'achat, sous forme d'un code, à utiliser lors
                      de votre prochaine commande
                      <span className=" text-sky-400 text-sm font-bold uppercase px-6 py-3 rounded-lg mr-1 mb-1 w-full">
                        {`Je veux un bon d'achat de ${orders.stripeProposedAmountCashback / 100} €`}
                      </span>
                    </button>
                    <button
                      type="submit"
                      className="flex flex-col justify-center items-center text-center text-sm bg-white w-full lg:w-1/2 min-h-44 shadow hover:shadow-lg outline-none focus:outline-none rounded-lg p-3"
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => setPreferedAction('refund')}
                    >
                      <i className="fas fa-arrows-rotate text-2xl text-sky-400 mb-3" />
                      Recevoir la somme d'ici quelques jours (environ 8 jours)
                      sur le compte associé à la carte bancaire
                      utilisée lors de la commande
                      <span className=" text-sky-400 text-sm font-bold uppercase px-6 py-3 rounded-lg mr-1 mb-1 w-full">
                        {`Je veux le remboursement ${orders.stripeProposedAmountRefund / 100} €`}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            {(!isLoading && preferedAction === 'cashback' && error === '')
              && (
                <div className="flex flex-col justify-center items-center text-gray-500 bg-white rounded-lg w-full min-h-96 p-6 shadow text-center">
                  <h1 className="text-sm lg:text-xl mb-3">
                    Vous allez recevoir un e-mail avec le code à utiliser.
                  </h1>
                  Vérifiez dans vos indésirables si vous ne le trouvez pas.
                  <br />
                  Merci et à très bientôt !
                </div>
              )}
            {(!isLoading && preferedAction === 'refund' && error === '')
              && (
                <div className="flex flex-col justify-center items-center text-gray-500 bg-white rounded-lg w-full min-h-96 p-6 shadow text-center">
                  <h1 className="text-sm lg:text-xl mb-3">
                    Vous allez recevoir votre remboursement d'ici quelques jours.
                    <br />
                    Merci et à très bientôt !
                  </h1>
                </div>
              )}
            {(!isLoading && error === 'token renewed')
              && (
                <div className="flex flex-col justify-center items-center text-gray-500 bg-white rounded-lg w-full min-h-96 p-6 shadow text-center">
                  <h1 className="text-sm lg:text-xl mb-3">
                    Ce lien n'est plus valide. Veuillez vérifier vos e-mails et utiliser le
                    dernier lien qui vous a été envoyé.
                    <br />
                    Merci et à très bientôt !
                  </h1>
                </div>
              )}
            {(!isLoading && error === 'no refund')
              && (
                <div className="flex flex-col justify-center items-center text-gray-500 bg-white rounded-lg w-full min-h-96 p-6 shadow text-center">
                  <h1 className="text-sm lg:text-xl mb-3">
                    Aucun remboursements ...
                    <br />
                    Merci et à très bientôt !
                  </h1>
                </div>
              )}
          </div>
        </section>
        <Footer />
      </main>
    );
  }
  navigate('/');
}
