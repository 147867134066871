/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

export default function GetPartOfDayByQuarter({
  timeStr,
}) {
  if (timeStr) {
    const hours = timeStr.split(':')[0];
    const hoursInt = parseInt(hours, 10);
    if (hoursInt <= 9) {
      return 'début de matinée';
    }
    if (hoursInt > 9 && hoursInt <= 12) {
      return 'fin de matinée';
    }
    if (hoursInt > 12 && hoursInt <= 15) {
      return 'début d\'après midi';
    }
    if (hoursInt > 15) {
      return 'fin d\'après midi';
    }
  }
  return '';
}
