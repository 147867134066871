/* eslint-disable react/prop-types */
import React from 'react';

export default function Images() {
  return (
    <>
      <div className="absolute top-0 left-3 w-1/2 h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="VOL SUNSET"
          className="absolute object-cover w-full h-full"
          src="/image/homeImages/paragliding_sunset_small.webp"
          loading="lazy"
        />
        <div className="group-hover:block hidden absolute w-full h-full bg-black/50 inset-x-0 text-center p-3">
          <div className="flex flex-col flex flex-col justify-center items-center w-full h-full text-white">
            <h3 className="text-sm lg:text-lg px-3 lg:px-6 w-full">
              VOL SUNSET
            </h3>
            <p className="text-xs">
              Vols solos encadrés sur le site du 800 m, Colimaçons, Saint-Leu
            </p>
          </div>
        </div>
      </div>
      <div className="absolute top-[80px] right-3 w-1/2 h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="apprendre à voler en parapente"
          className="absolute object-cover w-full h-full"
          src="/image/homeImages/paragliding_beatch_small.webp"
          loading="lazy"
        />
        <div className="group-hover:block hidden absolute w-full h-full bg-black/50 inset-x-0 text-center p-3">
          <div className="flex flex-col flex flex-col justify-center items-center w-full h-full text-white">
            <h3 className="text-sm lg:text-lg px-3 lg:px-6 w-full">
              Dune du Pila
            </h3>
            <p className="text-xs">
              Stage sur la dune du Pila
            </p>
          </div>
        </div>
      </div>
      <div className="absolute top-[180px] left-4 w-1/2 h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="Découverte de l&apos;ile - Volcan"
          className="absolute object-cover w-full h-full"
          src="/image/homeImages/paragliding_vulcan_small.webp"
          loading="lazy"
        />
        <div className="group-hover:block hidden absolute w-full h-full bg-black/50 inset-x-0 text-center p-3">
          <div className="flex flex-col flex flex-col justify-center items-center w-full h-full text-white">
            <h3 className="text-sm lg:text-lg px-3 lg:px-6 w-full">
              Découverte de l&apos;ile - Volcan
            </h3>
            <p className="text-xs">
              Formule stage perferctionnement decouverte de l&apos;ile
            </p>
          </div>
        </div>
      </div>
      <div className="absolute top-[260px] right-3 w-1/2 h-36 group overflow-hidden rounded-lg shadow-xl">
        <img
          alt="pente école"
          className="absolute object-cover w-full h-full"
          src="/image/homeImages/paragliding_school_small.webp"
          loading="lazy"
        />
        <div className="group-hover:block hidden absolute w-full h-full bg-black/50 inset-x-0 text-center p-3">
          <div className="flex flex-col flex flex-col justify-center items-center w-full h-full text-white">
            <h3 className="text-sm lg:text-lg px-3 lg:px-6 w-full text-balance">
              La pente école
            </h3>
            <p className="text-xs">
              Pour apprendre à voler
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
