/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Elements,
} from '@stripe/react-stripe-js';

import Navbar from '../components/navigation/Navbar';
import NavUpBtn from '../components/navigation/NavUpBtn';
import FixedCartBtn from '../components/navigation/FixedCartBtn';
import Footer from '../components/navigation/Footer';
import useScroll from '../components/useScroll';
import PaymentMethods from '../components/PaymentMethods';
import ModalNonEmptyCart from '../components/ModalNonEmptyCart';
import Payment from '../components/stripe/payment';
import Policy from '../components/biplace/Policy';

export default function MakeAGift({
  stripePromise,
  cart,
  setCart,
  purchaseStep,
  setPurchaseStep,
  tandemItems,
  courseItems,
  setError,
}) {
  const nameFormat = /^[a-zA-ZÀ-ú ._-]{3,20}$/;
  const mailFormat = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const ref = useRef();
  const { isScroll } = useScroll();
  const [isPILoading, setIsPILoading] = useState(false);
  const [showGiftMenu, setShowGiftMenu] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const [warnAboutNonEmptyCart, setWarnAboutNonEmptyCart] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [cartError, setCartError] = useState('');
  const CreateAGift = (itemType, itemID) => {
    setIsPILoading(true);
    if (cart.stripePaymentIntentID === '') {
      let params = '';
      if (itemType !== '' && itemID !== '') {
        if (itemType === 'tandem') params += `tandem_item_id=${itemID}`;
        if (itemType === 'course') params += `course_item_id=${itemID}`;
      }
      if ((itemType === '' && itemID === '') && customAmount > 10) {
        params += `custom_amount=${customAmount}`;
      }
      if (params === '') return;
      fetch(`${process.env.REACT_APP_API_URL}/public/paragliding/gift/order/create?${params}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((json) => {
          setIsPILoading(false);
          if (!json.error) {
            setCart(json);
          } else {
            const nerror = new Error('error');
            nerror.msg = json.error;
            throw nerror;
          }
        })
        .catch((err) => {
          setError(err);
        });
      return;
    }
    setIsPILoading(false);
    setError('erreur inconnue');
  };
  const UpdateAGift = (itemType, itemID) => {
    setIsPILoading(true);
    if (cart.stripePaymentIntentID !== '' && cart.id !== '') {
      let params = '';
      if (itemType !== '' && itemID !== '') {
        if (itemType === 'tandem') params += `tandem_item_id=${itemID}`;
        if (itemType === 'course') params += `course_item_id=${itemID}`;
      }
      if ((itemType === '' && itemID === '') && customAmount > 10) {
        params += `custom_amount=${customAmount}`;
      }
      if (params === '') return;
      fetch(`${process.env.REACT_APP_API_URL}/public/paragliding/gift/order/update?${params}&order_id=${cart.id}&stripe_payment_intent_id=${cart.stripePaymentIntentID}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((json) => {
          setIsPILoading(false);
          if (!json.error) {
            setCart(json);
          } else {
            const nerror = new Error('error');
            nerror.msg = json.error;
            throw nerror;
          }
        })
        .catch((err) => {
          setError(err);
        });
      return;
    }
    setIsPILoading(false);
    setError('erreur inconnue');
  };
  useEffect(() => {
    if (cart.total > 0) {
      setWarnAboutNonEmptyCart(true);
    }
  }, []);
  useEffect(() => {
    if (cart.grandTotal === 0) {
      setCartError('Séléctionnez un cadeau');
      return;
    }
    if (!cart.firstName || cart?.firstName.match(nameFormat) === null) {
      setCartError('Indiquez un prénom valide');
      return;
    }
    if (!cart.lastName || cart?.lastName.match(nameFormat) === null) {
      setCartError('Indiquez un nom valide');
      return;
    }
    if (!cart.email || cart?.email.match(mailFormat) === null) {
      setCartError('Indiquez votre e-mail');
      return;
    }
    if (!cart.giftIsForEmail || cart?.giftIsForEmail.match(mailFormat) === null) {
      setCartError('Indiquez l\'e-mail à la personne qui utilisera le cadeau');
      return;
    }
    if (cart.email === cart.giftIsForEmail) {
      setCartError('Vous ne pouvez pas indiquer le même e-mail');
      return;
    }
    if (!cart.isCGUAccepted) {
      setCartError('Acceptez les conditions');
      return;
    }
    setCartError('');
  }, [cart]);
  return (
    <main ref={ref} className="w-full font-montserrat text-gray-500">
      {warnAboutNonEmptyCart
        && (
          <ModalNonEmptyCart
            source="makeAGift"
            setModal={setWarnAboutNonEmptyCart}
          />
        )}
      <Navbar
        cart={cart}
        purchaseStep={purchaseStep}
        setPurchaseStep={setPurchaseStep}
        isScroll={isScroll}
        items={tandemItems}
      />
      {(isScroll && cart?.total === 0)
        && (
          <NavUpBtn />
        )}
      {(cart?.total > 0 && purchaseStep === 0)
        && (
          <FixedCartBtn
            cart={cart}
            setPurchaseStep={setPurchaseStep}
          />
        )}
      <section className="flex flex-col justify-center items-center mb-12 bg-white">
        <img
          alt="make a gift"
          className="object-cover w-full h-44 lg:h-80 mb-20"
          src="/image/slide-2.jpg"
        />
        <h1 className="text-3xl mb-3 font-playfair uppercase">
          Offrez une de nos préstations
        </h1>
        <h2 className="flex font-normal mb-6 lg:w-1/2 text-sm lg:text-base text-center">
          Vous pouvez offrir une de nos préstations à la personne de votre choix.
          <br />
          Vous recevrez un code correspondant au montant désiré
          qu&apos;elle pourra utiliser sur notre boutique en ligne.
        </h2>
        <div className="w-full flex flex-col justify-center items-center mb-12">
          <h1 className="font-light">
            Moyens de paiement acceptés:
          </h1>
          <PaymentMethods />
        </div>
        <div className="flex flex-col justify-center px-3 lg:px-6 w-full lg:w-1/2 text-xs lg:text-sm">
          <h3 className="text-sm lg:text-xl uppercase text-left mr-3 w-full">
            Cadeau à offrir
          </h3>
          <div className="relative">
            {(!tandemItems.isLoading && tandemItems?.data
              && !courseItems.isLoading && courseItems?.data)
              && (
                <button
                  type="button"
                  className="flex justify-between items-center mb-6 shadow rounded px-3 py-2 w-96"
                  onClick={() => setShowGiftMenu(!showGiftMenu)}
                >
                  {cart?.stripePaymentIntentID && cart?.grandTotal > 0
                    && `${cart.grandTotal / 100}€`}
                  {cart?.customAmount
                    && (
                      'Montant personnalisé'
                    )}
                  {(!cart?.stripePaymentIntentID && !cart?.customAmount)
                    && (
                      'Choix du cadeau'
                    )}
                  <i className="fas fa-angle-down" />
                </button>
              )}
            {showGiftMenu
              && (
                <div className="flex flex-col bg-white w-96 h-auto z-20 absolute top-8 border shadow-lg mb-3">
                  <button
                    type="button"
                    className="text-left ml-3 hover:text-sky-400 mt-3"
                    onClick={() => {
                      setCart({
                        stripePaymentIntentClientSecret: '',
                        stripePaymentIntentID: '',
                        grandTotal: 0,
                        isAGift: true,
                        customAmount: true,
                      });
                      setShowGiftMenu(false);
                    }}
                  >
                    Saisir un montant personnalisé
                  </button>
                  <span className="text-gray-400 text-left ml-3 w-full mt-3">Les bâptemes</span>
                  {tandemItems?.data.ParaglidingItems.tandem?.map((x) => (
                    <button
                      type="button"
                      className="text-left ml-6 hover:text-sky-400"
                      onClick={() => {
                        if (cart.stripePaymentIntentID) {
                          UpdateAGift('tandem', x.id, '0');
                          setShowGiftMenu(false);
                          return;
                        }
                        CreateAGift('tandem', x.id, '0');
                        setShowGiftMenu(false);
                      }}
                    >
                      {`Bapteme ${x.name} - ${x.price / 100}€`}
                    </button>
                  ))}
                  <span className="text-gray-400 text-left ml-3 w-full mt-3">Les Formations</span>
                  {courseItems?.data.ParaglidingItems.course?.map((x) => (
                    <button
                      type="button"
                      className="text-left ml-6 hover:text-sky-400"
                      onClick={() => {
                        if (cart.stripePaymentIntentID) {
                          UpdateAGift('tandem', x.id, '0');
                          setShowGiftMenu(false);
                          return;
                        }
                        CreateAGift('tandem', x.id, '0');
                        setShowGiftMenu(false);
                      }}
                    >
                      {`Formation ${x.name} - ${(x.price * x.durationDays) / 100}€`}
                    </button>
                  ))}
                </div>
              )}
            {(cart?.stripePaymentIntentID && cart?.grandTotal > 0)
              && (
                <div className="relative bg-emerald-300 px-3 py-2 rounded-lg text-white text-center w-full lg:w-96 mb-6">
                  <i className="absolute top-1/2 -translate-y-1/2 left-1 fa fa-circle-check text-lg" />
                  {`Carte cadeau d'un montant de ${cart.grandTotal / 100}€`}
                </div>
              )}
          </div>
          {cart?.customAmount
            && (
              <div className="flex flex-col justify-start items-start w-full mb-3">
                <label htmlFor="customAmount" className="flex flex-col justify-center w-auto mb-6">
                  <p className="text-sm">
                    Montant personnalisé (minimum 10€)
                  </p>
                  <input
                    type="number"
                    id="customAmount"
                    className="appearance-none outline-none px-3 py-2 w-20 rounded shadow"
                    min="10"
                    value={customAmount / 100}
                    onChange={(e) => setCustomAmount(parseInt(e?.target?.value, 10) * 100)}
                  />
                </label>
                <button
                  type="button"
                  className={`${customAmount < 10 ? 'bg-white text-gray-400' : 'bg-sky-400 text-white'} px-3 py-2 rounded-lg`}
                  disabled={customAmount < 10}
                  onClick={() => {
                    if (cart.stripePaymentIntentID) {
                      UpdateAGift('', '', `${customAmount}`);
                      setShowGiftMenu(false);
                      return;
                    }
                    CreateAGift('', '', `${customAmount}`);
                    setShowGiftMenu(false);
                  }}
                >
                  {customAmount < 10
                    ? 'Le montant doit être supérieur à 10€'
                    : 'Valider le montant saisi'}
                </button>
              </div>
            )}
          {/* Make the order contact  */}
          <h3 className="text-sm lg:text-xl uppercase text-left mr-3 w-full">
            Informations
          </h3>
          <p className="normal-case text-xs text-left mb-3 w-full text-gray-400">
            Vous recevrez le détails de la commande et le code
            sur ce contact
          </p>
          <div className="flex flex-col lg:flex-row justify-center items-center mb-3 w-full lg:gap-2">
            <label htmlFor="firstName" className="flex flex-col justify-center w-full lg:w-full">
              <p className="text-sm">
                Votre Prénom
              </p>
              <div className="flex items-center shadow rounded">
                <input
                  type="text"
                  id="firstName"
                  className="appearance-none outline-none px-3 py-3 w-full rounded"
                  onChange={(e) => setCart({
                    ...cart,
                    firstName: e.target.value,
                  })}
                />
              </div>
            </label>
            <label htmlFor="lastName" className="flex flex-col justify-center w-full lg:w-full">
              <p className="text-sm">
                Votre Nom
              </p>
              <div className="flex items-center shadow rounded">
                <input
                  type="text"
                  id="lastName"
                  className="appearance-none outline-none px-3 py-3 w-full rounded"
                  onChange={(e) => setCart({
                    ...cart,
                    lastName: e.target.value,
                  })}
                />
              </div>
            </label>
          </div>
          <label htmlFor="email" className="flex flex-col mb-3 lg:mb-6 w-1/2">
            <p className="text-sm">
              Votre E-Mail
            </p>
            <div className="flex items-center shadow rounded w-full">
              <input
                type="text"
                id="email"
                className="appearance-none outline-none px-3 py-3 w-full rounded"
                value={cart.email || ''}
                onChange={(e) => {
                  setCart({
                    ...cart,
                    email: e?.target?.value,
                  });
                  setShowPayment(false);
                }}
              />
              {(cart?.email && cart?.email.match(mailFormat) !== null)
                && <i className="mx-2 inline text-green-400 fa-solid fa-check" />}
              {(!cart?.email || cart?.email.match(mailFormat) === null)
                && <i className="mx-2 inline text-rose-400 fa-solid fa-xmark" />}
            </div>
          </label>
          {/* Make the order contact  */}
          <p className="normal-case text-xs text-left mb-3 w-full text-gray-400">
            Renseignez bien
            l&apos;adresse e-mail de la personne à qui
            vous offrez ce cadeau. Sans cet e-mail il ne pourra pas utiliser
            le code
          </p>
          <div className="flex flex-col lg:flex-row justify-center items-center mb-6 w-full lg:gap-2">
            <label htmlFor="giftIsForEmail" className="flex flex-col lg:mb-0 w-full">
              <p className="text-sm">
                L&apos;e-mail de la personne qui utilisera le code
              </p>
              <div className="flex items-center shadow rounded w-full lg:w-1/2">
                <input
                  type="text"
                  id="giftIsForEmail"
                  className="appearance-none outline-none px-3 py-3 w-full rounded"
                  value={cart.giftIsForEmail || ''}
                  onChange={(e) => {
                    setCart({
                      ...cart,
                      giftIsForEmail: e?.target?.value,
                    });
                    setShowPayment(false);
                  }}
                />
                {(cart?.giftIsForEmail && cart?.giftIsForEmail.match(mailFormat) !== null)
                  && <i className="mx-2 inline text-green-400 fa-solid fa-check" />}
                {(!cart?.giftIsForEmail || cart?.giftIsForEmail.match(mailFormat) === null)
                  && <i className="mx-2 inline text-rose-400 fa-solid fa-xmark" />}
              </div>
            </label>
          </div>
          <Policy cart={cart} setCart={setCart} setShowPayment={setShowPayment} />

          {/* Some checks */}
          {(cartError === '' && !showPayment)
            && (
              <div className="flex justify-center items-center animate-fade">
                <button
                  type="button"
                  className="bg-sky-400 text-white text-center w-80 px-3 py-2 rounded-lg shadow-lg"
                  onClick={() => setShowPayment(true)}
                >
                  Confirmer et procéder au paiement
                </button>
              </div>
            )}
          {(cartError !== '' && !showPayment)
            && (
              <div className="flex justify-center items-center animate-fade">
                <div className="flex justify-center items-center animate-fade bg-sky-400 text-white text-center w-80 px-3 py-2 rounded-lg shadow-lg">
                  {cartError}
                </div>
              </div>
            )}
          {(cart.grandTotal > 0 && cart.isCGUAccepted && showPayment && !isPILoading)
            && (
              <Elements
                mode="payment"
                stripe={stripePromise}
                options={{ clientSecret: cart.stripePaymentIntentClientSecret }}
              >
                <Payment
                  stripePromise={stripePromise}
                  cart={cart}
                  setCart={setCart}
                  setError={setError}
                  letUserToEnterPromoOrCashbackCode={false}
                />
              </Elements>
            )}
        </div>
        <i className="fas fa-gift text-white text-4xl lg:text-8xl absolute right-[40px] lg:right-[40px] top-[calc(10%+40px)] lg:top-[calc(20%-30px)]" />
        <i className="fas fa-gift text-white/90 text-2xl lg:text-6xl absolute right-[80px] lg:right-[130px] top-[calc(10%+55px)] lg:top-[calc(20%+10px)] text-gray-400" />
        <i className="fas fa-gift text-white/50 text-xl lg:text-2xl absolute right-[20px] lg:right-[20px] top-[calc(10%+55px)] lg:top-[calc(20%+40px)] text-gray-400" />
      </section>
      <Footer />
    </main>
  );
}
