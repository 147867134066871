/* eslint-disable react/prop-types */
import React from 'react';

export default function CartDetails({
  tandemOrderItem,
  items,
}) {
  if (!items.isLoading) {
    const itemDetails = items.data.ParaglidingItems.tandem.find(
      (x) => x.id === tandemOrderItem.itemID,
    );
    return (
      <li key={tandemOrderItem.id} className="mx-2">
        {`- ${itemDetails?.name} ${tandemOrderItem.itemPrice / 100} €`}
        <ul className="mx-4">
          <li className="mb-1">
            {itemDetails?.isPhotosIncluded
            && ' Album photos offert '}
            {!itemDetails?.isPhotosIncluded && tandemOrderItem.wantPhotos
              && ` Avec album photo (+${tandemOrderItem.photosPrice} €)`}
            {!itemDetails?.isPhotosIncluded && !tandemOrderItem.wantPhotos
              && ' Sans album photo'}
          </li>
        </ul>
      </li>
    );
  }
  return (
    <li className="mx-2 animate-pulse">
      Chargement
    </li>
  );
}
