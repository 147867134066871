/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';

export default function PassengerAgeAndWeightForm({
  cart,
  setCart,
  tandemOrderItem,
  itemDetails,
  isValidAge,
  isValidWeight,
  isValidAgeAndWeight,
  ageAndWeightRequirements,
  setShowAgeAndWeightForm,
}) {
  return (
    <div className="absolute flex flex-col justify-center items-center w-full h-full z-30 bg-white/80 lg:bg-white/70">
      <div className="flex flex-col justify-center lg:justify-start items-center mb-3 w-full">
        <label htmlFor={`age_${tandemOrderItem.id}`} className="flex flex-col justify-center w-1/2 lg:w-1/4 mb-6 bg-white">
          <p className="text-sm mb-1">
            Age
          </p>
          <div className={`flex items-center shadow rounded outline outline-1 ${isValidAge ? 'outline-emerald-400' : 'outline-rose-400'}`}>
            <input
              type="number"
              id={`age_${tandemOrderItem.id}`}
              className="appearance-none outline-none px-3 py-3 w-full rounded"
              min={itemDetails.name === 'Marmay' ? ageAndWeightRequirements.minMarmayAge : ageAndWeightRequirements.minAdultAge}
              max={itemDetails.name === 'Marmay' ? ageAndWeightRequirements.maxMarmayAge : ageAndWeightRequirements.maxAdultAge}
              value={tandemOrderItem?.age || ''}
              onChange={(e) => setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.map((j) => {
                  if (j.id === tandemOrderItem.id) {
                    return {
                      ...j,
                      eventID: null,
                      age: parseInt(e?.target?.value, 10),
                    };
                  }
                  return j;
                }),
              })}
            />
            {isValidAge
              ? <i className="mx-2 inline text-green-400 fa-solid fa-check" />
              : <i className="mx-2 inline text-rose-400 fa-solid fa-xmark" />}
          </div>
        </label>
        <label htmlFor={`weight_${tandemOrderItem.id}`} className="flex flex-col justify-center w-1/2 lg:w-1/4 mb-6 bg-white">
          <p className="text-sm mb-1">
            Poids (Kg)
          </p>
          <div className={`flex items-center shadow rounded outline outline-1 ${isValidWeight ? 'outline-emerald-400' : 'outline-rose-400 active:outline-sky-400'}`}>
            <input
              type="number"
              id={`weight_${tandemOrderItem.id}`}
              className="appearance-none outline-none px-3 py-3 w-full rounded"
              min={itemDetails.name === 'Marmay' ? ageAndWeightRequirements.minMarmayWeight : ageAndWeightRequirements.minAdultWeight}
              max={itemDetails.name === 'Marmay' ? ageAndWeightRequirements.maxMarmayWeight : ageAndWeightRequirements.maxAdultWeight}
              value={tandemOrderItem?.weight || ''}
              onChange={(e) => setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.map((j) => {
                  if (j.id === tandemOrderItem.id) {
                    return {
                      ...j,
                      eventID: null,
                      weight: parseInt(e?.target?.value, 10),
                    };
                  }
                  return j;
                }),
              })}
            />
            {isValidWeight
              ? <i className="mx-2 inline text-green-400 fa-solid fa-check" />
              : <i className="mx-2 inline text-rose-400 fa-solid fa-xmark" />}
          </div>
        </label>
      </div>
      {(isValidAgeAndWeight && !tandemOrderItem.eventID)
        && (
          <button
            type="button"
            className="relative w-44 h-auto mb-3 bg-sky-400 rounded-xl shadow-lg text-white uppercase px-3 py-3"
            onClick={() => setShowAgeAndWeightForm(false)}
          >
            Confirmer
          </button>
        )}
    </div>
  );
}
