/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

export default function Items({
  cart,
  items,
  AddOrDeleteOrderItem,
  isPILoading,
}) {
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (!items.isLoading) {
      setImages(items.data?.ParaglidingItems?.tandem?.map((x) => ({
        id: x.id,
        count: 0,
      })));
    }
  }, [items.isLoading]);
  return (
    <div className="flex flex-wrap justify-center items-center text-gray-500 px-3">
      {!items.isLoading && items?.data?.ParaglidingItems?.tandem?.map((x) => (
        <div id={`${x.picturePath}`} key={x.id} className="flex items-stretch w-1/4 min-w-full lg:min-w-[450px] h-full group p-3">
          <div className="flex flex-col justify-start shadow-lg group-hover:shadow-xl w-full h-full rounded-lg bg-white">
            <div className="relative">
              {/* Picture control buttons */}
              <button
                type="button"
                className="absolute right-2 top-1/2 -translate-y-1/2 group-hover:block lg:hidden bg-transparent px-3 lg:px-5 py-5 z-30"
                aria-label="right"
                onClick={() => setImages(images?.map((k) => {
                  if (k.id === x.id) {
                    if (k.count >= 2) return ({ id: k.id, count: 0 });
                    return ({ id: k.id, count: k.count + 1 });
                  }
                  return k;
                }))}
              >
                <i className="fa-solid fa-circle-right text-xl text-white " />
              </button>
              <button
                type="button"
                className="absolute left-2 top-1/2 -translate-y-1/2 group-hover:block lg:hidden bg-transparent px-3 lg:px-5 py-5 z-30"
                aria-label="left"
                onClick={() => setImages(images?.map((k) => {
                  if (k.id === x.id) {
                    if (k.count === 0) return ({ id: k.id, count: 2 });
                    return ({ id: k.id, count: k.count - 1 });
                  }
                  return k;
                }))}
              >
                <i className="fa-solid fa-circle-left text-xl text-white " />
              </button>
              {/* End Picture control buttons */}
              <img
                alt={`${x.picturePath}`}
                className="object-cover w-full h-48 rounded-t-lg"
                src={`/image/paragliding/tandem/${x.picturePath}-${images?.find((k) => k.id === x.id)?.count}.webp`}
                loading="lazy"
              />
            </div>
            <div className="flex flex-col justify-start items-center p-3">
              <div className="flex justify-between w-full mb-3">
                <h3 className="text-xl font-playfair font-semibold">
                  <span className="text-[#ceb071]">
                    {x.name}
                  </span>
                </h3>
                <p className="text-xl font-light text-right text-gray-400">
                  {`€ ${x.price / 100}`}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center mb-6 font-light w-full">
                <div className="font-light text-sm lg:text-base mb-2">
                  {x.shortDesc}
                </div>
                <hr className="w-1/2 py-2" />
                <div className="flex justify-between items-center text-[11px] w-full gap-1">
                  <div className="flex flex-col justify-center items-center text-center w-1/3">
                    <i className="fas fa-clock mb-2 text-base" />
                    Environ
                    <br />
                    {`${x?.durationMinutesClientPOV} minutes`}
                  </div>
                  <div className="flex flex-col justify-center items-center text-center w-1/3">
                    <img
                      src="/image/icons/icon-gopro.svg"
                      alt="video_photos_free"
                      className="w-auto h-[20px] lg:h-[20px] animate-pulse mb-2"
                    />
                    {x.isPhotosIncluded
                      ? (
                        <div className="text-[#ceb071] font-medium text-balance">
                          Album HD
                          <span className="mx-1 line-through ">
                            25€
                          </span>
                          <br />
                          OFFERT
                        </div>
                      ) : 'Non inclus'}
                  </div>
                  <div className="flex flex-col justify-center items-center text-center w-1/3">
                    <i className="fas fa-mountain-sun mb-2 text-base" />
                    Départ à
                    <br />
                    {`${x.altitude}M d'altitude`}
                  </div>
                </div>
                <div className="flex justify-between items-center text-[11px] w-full gap-1">
                  <div className="flex flex-col justify-center items-center text-center w-1/3">
                    <i className="fa-regular fa-snowflake mb-2 text-sm" />
                    Climatisation
                  </div>
                  <div className="flex flex-col justify-center items-center text-center w-1/3 text-[#ceb071] font-medium text-balance">
                    <i className="fas fa-mug-hot mb-2 text-sm text-gray-500" />
                    Une boisson chaude OFFERTE
                  </div>
                  <div className="flex flex-col justify-center items-center text-center w-1/3">
                    <i className="fas fa-restroom mb-2 text-sm" />
                    Toilettes privés
                  </div>
                </div>
              </div>
              <div className="text-xs lg:text-sm h-40 lg:h-48 overflow-auto text-center text-balance" style={{ whiteSpace: 'pre-wrap' }}>
                {x.longDesc}
              </div>
            </div>
            <div className="flex justify-center items-center p-3">
              {(cart.grandTotal === 0 && !isPILoading)
                && (
                  <button
                    type="button"
                    className="flex justify-center items-center rounded-lg px-5 py-2 w-80 bg-sky-500 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-sky-400 transition-all ease-out duration-300"
                    aria-label="more"
                    onClick={() => {
                      AddOrDeleteOrderItem('add', x.id);
                    }}
                  >
                    Mon Billet
                    <img
                      src="/image/icons/tickets.svg"
                      alt="tickets"
                      className="w-auto h-[20px] lg:h-[30px] ml-3"
                    />
                  </button>
                )}
              {(cart.grandTotal > 0 && !isPILoading)
                && (
                  <div className="flex justify-center items-center p-1 w-full">
                    <div className="flex justify-center items-center w-1/2 bg-white shadow rounded-xl py-2">
                      <button
                        type="button"
                        className={`w-1/3
                        ${cart.tandemOrderItems?.filter((j) => j.itemID === x.id)?.length === 0
                          ? ' text-zinc-300'
                          : ' text-zinc-600'}`}
                        aria-label="less"
                        disabled={
                          cart.tandemOrderItems?.find((j) => j.itemID === x.id)
                            ? cart.tandemOrderItems?.filter(
                              (j) => j.itemID === x.id,
                            )?.length === 0
                            : true
                          }
                        onClick={() => {
                          const orderItemToDelete = cart.tandemOrderItems?.find(
                            (j) => j.itemID === x.id,
                          );
                          AddOrDeleteOrderItem('delete', orderItemToDelete?.id);
                        }}
                      >
                        <i className="fas fa-minus text-xl" />
                      </button>
                      <div className="text-center w-1/3">
                        {cart.tandemOrderItems.filter((j) => j.itemID === x.id)?.length}
                      </div>
                      <button
                        type="button"
                        className="w-1/3"
                        aria-label="more"
                        onClick={() => {
                          AddOrDeleteOrderItem('add', x.id);
                        }}
                      >
                        <i className="fas fa-plus text-xl" />
                      </button>
                    </div>
                  </div>
                )}
              {(isPILoading)
                && (
                  <div className="p-1 w-full">
                    <button
                      type="button"
                      className="flex justify-center items-center AddictSkyButton w-full"
                      aria-label="more"
                      disabled
                    >
                      <i className="fas fa-spinner animate-spin py-3" />
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      ))}
      {items.isLoading
        && (
          <>
            <div className="flex items-stretch w-1/4 min-w-96 h-full group p-3 ">
              <div className="flex flex-col justify-start shadow group-hover:shadow-xl w-full h-full rounded-lg bg-white">
                <div className="flex flex-col animate-pulse">
                  <div className="flex justify-center items-center rounded-lg bg-slate-700 h-48 w-full mb-1 text-white">
                    <i className="fa-solid fa-spinner animate-spin text-2xl mr-3" />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-3">
                    <div className="rounded bg-slate-700 h-8" />
                    <div className="rounded bg-slate-700 h-8" />
                  </div>
                  <div className="grid grid-cols-6 gap-x-3 gap-y-1 mb-3 mb-1">
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="rounded bg-slate-700 h-8 w-full" />
                    <div className="rounded bg-slate-700 h-8 w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-stretch w-1/4 min-w-96 h-full group p-3 ">
              <div className="flex flex-col justify-start shadow group-hover:shadow-xl w-full h-full rounded-lg bg-white">
                <div className="flex flex-col animate-pulse">
                  <div className="flex justify-center items-center rounded-lg bg-slate-700 h-48 w-full mb-1 text-white">
                    <i className="fa-solid fa-spinner animate-spin text-2xl mr-3" />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-3">
                    <div className="rounded bg-slate-700 h-8" />
                    <div className="rounded bg-slate-700 h-8" />
                  </div>
                  <div className="grid grid-cols-6 gap-x-3 gap-y-1 mb-3 mb-1">
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="rounded bg-slate-700 h-8 w-full" />
                    <div className="rounded bg-slate-700 h-8 w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-stretch w-1/4 min-w-96 h-full group p-3 ">
              <div className="flex flex-col justify-start shadow group-hover:shadow-xl w-full h-full rounded-lg bg-white">
                <div className="flex flex-col animate-pulse">
                  <div className="flex justify-center items-center rounded-lg bg-slate-700 h-48 w-full mb-1 text-white">
                    <i className="fa-solid fa-spinner animate-spin text-2xl mr-3" />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-3">
                    <div className="rounded bg-slate-700 h-8" />
                    <div className="rounded bg-slate-700 h-8" />
                  </div>
                  <div className="grid grid-cols-6 gap-x-3 gap-y-1 mb-3 mb-1">
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="rounded bg-slate-700 h-8 w-full" />
                    <div className="rounded bg-slate-700 h-8 w-full" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-stretch w-1/4 min-w-96 h-full group p-3 ">
              <div className="flex flex-col justify-start shadow group-hover:shadow-xl w-full h-full rounded-lg bg-white">
                <div className="flex flex-col animate-pulse">
                  <div className="flex justify-center items-center rounded-lg bg-slate-700 h-48 w-full mb-1 text-white">
                    <i className="fa-solid fa-spinner animate-spin text-2xl mr-3" />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-3">
                    <div className="rounded bg-slate-700 h-8" />
                    <div className="rounded bg-slate-700 h-8" />
                  </div>
                  <div className="grid grid-cols-6 gap-x-3 gap-y-1 mb-3 mb-1">
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-3" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                    <div className="rounded bg-slate-700 h-2 col-span-2" />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="rounded bg-slate-700 h-8 w-full" />
                    <div className="rounded bg-slate-700 h-8 w-full" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}
