/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { intervalToDuration, isSameDay } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { fr } from 'date-fns/locale';
import CheckPassengerAgeAndWeight from './CheckPassengerAgeAndWeight';
import PassengerIdentityForm from './PassengerIdentityForm';
import ConfirmDate from './ConfirmDate';
import OrderItemDetails from './OrderItemDetails';
import PassengerAgeAndWeightForm from './PassengerAgeAndWeightForm';
import CountDownExpirationDate from '../CountDownExpirationDate';

export default function PassengerSelectDate({
  cart,
  setCart,
  tandemOrderItem,
  itemDetails,
  eventsDetails,
  setEventsDetails,
  month,
  items,
  dates,
  setDates,
  setMonth,
  setError,
  orderIdx,
  setOrderIdx,
  passengerRefs,
  paymentRef,
  setShowPayment,
}) {
  // const calendarRef = useRef();
  const ageAndWeightRequirements = {
    minMarmayAge: 5,
    maxMarmayAge: 12,
    minMarmayWeight: 10,
    maxMarmayWeight: 40,
    minAdultAge: 12,
    maxAdultAge: 80,
    minAdultWeight: 40,
    maxAdultWeight: 90,
  };
  const bookedStyle = { backgroundColor: '#38bdf8', color: '#ffffff' };
  const [getEventsIsLoading, setGetEventsIsLoading] = useState(false);
  const [isValidAgeAndWeight, setIsValidAgeAndWeight] = useState(false);
  const [isValidAge, setIsValidAge] = useState(false);
  const [isValidWeight, setIsValidWeight] = useState(false);
  const [showAgeAndWeightForm, setShowAgeAndWeightForm] = useState(!tandemOrderItem.eventID);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [zoomInDate, setZoomInDate] = useState(false);
  const [animateConfirmDate, setAnimateConfirmDate] = useState(false);
  const [showIdentityForm, setShowIdentityForm] = useState(false);
  const [showCompletedOrderItem, setShowCompletedOrderItem] = useState(false);
  const [initialTime, setInitialTime] = useState(0);
  const nameFormat = /^[a-zA-ZÀ-ú ._-]{3,20}$/;
  useEffect(() => {
    if (!showAgeAndWeightForm) {
      // Fetch dates in month
      if (month && tandemOrderItem?.itemID) {
        setGetEventsIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/public/paragliding/tandem/events`;
        const monthParam = `month_view_date=${month.toLocaleDateString('fr-CA')}`;
        const tandeOrderItemID = `tandem_order_item_id=${tandemOrderItem?.itemID}`;
        const paymentIntentID = `payment_intent_id=${cart?.stripePaymentIntentID}`;
        const tandemItemID = `tandem_item_id=${tandemOrderItem.itemID}`;
        // To get generic dates if user haven't filled weight
        let weight = 'weight=20';
        if (!tandemOrderItem.weight) {
          const itemName = items?.find((j) => j.id === tandemOrderItem?.itemID)?.name;
          if (itemName !== 'Marmay') {
            weight = 'weight=44';
          }
        } else {
          weight = `weight=${tandemOrderItem.weight}`;
        }
        fetch(`${url}?${monthParam}&${tandeOrderItemID}&${paymentIntentID}&${tandemItemID}&${weight}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        })
          .then((r) => r.json())
          .then((json) => {
            setGetEventsIsLoading(false);
            if (!json.error) {
              setDates(json);
              return;
            }
            if (json.error) {
              const error = new Error('error');
              error.msg = json.error;
              throw error;
            }
          })
          .catch((err) => {
            setGetEventsIsLoading(false);
            setError(err.msg);
          });
      }
    }
  }, [
    month,
    isValidAgeAndWeight,
    showAgeAndWeightForm,
  ]);
  useEffect(() => {
    if (!showAgeAndWeightForm) {
      // Fetch hours in days
      if (zoomInDate && tandemOrderItem?.itemID && selectedDate && isValidAgeAndWeight) {
        setAnimateConfirmDate(true);
        const url = `${process.env.REACT_APP_API_URL}/public/paragliding/tandem/events`;
        const zoom = `zoom=${selectedDate.toLocaleDateString('fr-CA')}`;
        const tandeOrderItemID = `tandem_order_item_id=${tandemOrderItem.id}`;
        const paymentIntentID = `payment_intent_id=${cart?.stripePaymentIntentID}`;
        const tandemItemID = `tandem_item_id=${tandemOrderItem.itemID}`;
        const weight = `weight=${tandemOrderItem.weight}`;
        fetch(`${url}?${zoom}&${tandeOrderItemID}&${paymentIntentID}&${tandemItemID}&${weight}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        })
          .then((r) => r.json())
          .then((json) => {
            if (!json.error) {
              if (json.length > 0) {
                const eventsDetailsExistForOrderItem = eventsDetails?.find(
                  (x) => x.orderItemID === tandemOrderItem.id,
                );
                if (eventsDetailsExistForOrderItem) {
                  setEventsDetails(eventsDetails?.map((x) => {
                    if (x.orderItemID === tandemOrderItem.id) {
                      return ({
                        date: json[0].date,
                        startTime: json[0].startTime,
                        endTime: json[0].endTime,
                        eventID: json[0].eventID,
                        orderItemID: tandemOrderItem.id,
                      });
                    }
                    return x;
                  }));
                } else {
                  setEventsDetails([...eventsDetails, {
                    date: json[0].date,
                    startTime: json[0].startTime,
                    endTime: json[0].endTime,
                    eventID: json[0].eventID,
                    orderItemID: tandemOrderItem.id,
                  }]);
                }
                setCart({
                  ...cart,
                  tandemOrderItems: cart.tandemOrderItems.map((x) => {
                    if (x.id === tandemOrderItem.id) {
                      return {
                        ...x,
                        eventID: json[0].eventID,
                      };
                    }
                    return x;
                  }),
                });
                setSelectedDate(new Date(json[0].date));
                setShowAgeAndWeightForm(false);
                setZoomInDate(false);
              } else {
                const error = new Error('error');
                error.msg = 'bad zoom in date';
                throw error;
              }
              // setSlots(json);
            } else {
              const error = new Error('error');
              error.msg = json.error;
              throw error;
            }
          })
          .catch((err) => {
            setError(err.msg);
          });
      }
    }
    if (tandemOrderItem?.expirationDate) {
      const expirationDate = new Date(tandemOrderItem?.expirationDate);
      // const expirationDateWithoutTZ = new Date(
      //   expirationDate.getUTCFullYear(),
      //   expirationDate.getUTCMonth(),
      //   expirationDate.getUTCDate(),
      //   expirationDate.getUTCHours(),
      //   expirationDate.getUTCMinutes(),
      // );
      const duration = intervalToDuration({
        start: new Date(),
        end: expirationDate,
      });
      const timeLeft = (duration.minutes * 60) + duration.seconds;
      if (timeLeft > 0) {
        setInitialTime(timeLeft);
      }
    }
  }, [
    isValidAgeAndWeight,
    zoomInDate,
    showAgeAndWeightForm,
    tandemOrderItem?.expirationDate,
    selectedDate,
  ]);

  return (
    <>
      {initialTime > 0
        && (
          <CountDownExpirationDate initialTime={initialTime} />
        )}
      <OrderItemDetails
        cart={cart}
        setCart={setCart}
        setSelectedDate={setSelectedDate}
        tandemOrderItem={tandemOrderItem}
        items={items}
        eventsDetails={eventsDetails}
        setEventsDetails={setEventsDetails}
        showAgeAndWeightForm={showAgeAndWeightForm}
        setShowAgeAndWeightForm={setShowAgeAndWeightForm}
        showIdentityForm={showIdentityForm}
        setShowIdentityForm={setShowIdentityForm}
        showCompletedOrderItem={showCompletedOrderItem}
        setShowCompletedOrderItem={setShowCompletedOrderItem}
        setShowPayment={setShowPayment}
      />
      {!showIdentityForm
        && (
          <div className="relative flex justify-center items-center w-full">
            {/* Age & Weight of passenger */}
            {(showAgeAndWeightForm)
              && (
                <PassengerAgeAndWeightForm
                  cart={cart}
                  setCart={setCart}
                  tandemOrderItem={tandemOrderItem}
                  itemDetails={itemDetails}
                  isValidAge={isValidAge}
                  isValidWeight={isValidWeight}
                  isValidAgeAndWeight={isValidAgeAndWeight}
                  ageAndWeightRequirements={ageAndWeightRequirements}
                  setShowAgeAndWeightForm={setShowAgeAndWeightForm}
                />
              )}
            {(isValidAgeAndWeight && tandemOrderItem.eventID)
              && (
                <div className={`flex flex-col justify-center lg:justify-start items-center w-full lg:w-2/3 h-auto px-3 py-12 ${animateConfirmDate && 'animate-fade'}`} onAnimationEnd={() => setAnimateConfirmDate(false)}>
                  <ConfirmDate
                    cart={cart}
                    setCart={setCart}
                    tandemOrderItem={tandemOrderItem}
                    eventsDetails={eventsDetails}
                    setEventsDetails={setEventsDetails}
                    setShowAgeAndWeightForm={setShowAgeAndWeightForm}
                    setShowIdentityForm={setShowIdentityForm}
                    setShowCompletedOrderItem={setShowCompletedOrderItem}
                    setShowPayment={setShowPayment}
                  />
                </div>
              )}
            {/* Calendar */}
            {(!tandemOrderItem.eventID)
              && (
                <DayPicker
                  showOutsideDays
                  fixedWeeks
                  mode="single"
                  month={month}
                  onMonthChange={setMonth}
                  selected={selectedDate}
                  onSelect={(date) => { setSelectedDate(date); setZoomInDate(true); }}
                  modifiers={{
                    booked: dates?.map((j) => {
                      if (isSameDay(selectedDate, j.date)) return undefined;
                      return new Date(j.date);
                    }),
                  }}
                  modifiersStyles={{ booked: bookedStyle }}
                  locale={fr}
                  disabled={(day) => !dates?.some((j) => isSameDay(day, new Date(j.date)))}
                  required
                  classNames={{
                    vhidden: 'sr-only',
                    caption: 'flex justify-center items-center h-10',
                    root: 'text-gray-400 text-xs lg:text-sm',
                    months: 'flex gap-4 relative px-4',
                    caption_label: 'text-base lg:text-xl px-1',
                    nav_button:
                      'inline-flex justify-center items-center absolute top-0 w-10 h-10',
                    nav_button_next: 'right-0',
                    nav_button_previous: 'left-0',
                    table: 'border-collapse border-spacing-0',
                    head_cell: 'w-10 h-10 uppercase align-middle text-center',
                    cell: 'w-[40px] lg:w-[55px] h-[40px] lg:h-[55px] align-middle text-center border-0 text-xs',
                    day: 'w-[40px] lg:w-[45px] h-[40px] lg:h-[45px] rounded-full transition-colors hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-300 focus-visible:ring-opacity-50 active:bg-sky-600 active:text-white',
                    day_selected: 'text-white bg-sky-300 hover:bg-sky-500 border-2 border-sky-400',
                    day_today: 'font-bold',
                    day_disabled: 'opacity-25 hover:bg-white active:bg-white active:text-gray-800',
                  }}
                />
              )}
            {(isValidAgeAndWeight && !tandemOrderItem.eventID && !showAgeAndWeightForm)
              && (
                <div className="absolute flex flex-col justify-center items-center text-center w-full max-w-96 bg-transparent -bottom-12 lg:-bottom-10 z-40 px-3">
                  <div className="relative flex justify-center items-center w-auto h-auto mb-3 bg-sky-400 rounded-xl shadow-lg text-white animate-fade">
                    <div className="absolute right-2 rounded-full bg-white text-sky-400 shadow-lg w-6 h-6 text-center animate-bounce">
                      <i className="fas fa-arrow-up text-sm font-bold" />
                    </div>
                    <div className=" w-full px-12 py-3  flex justify-center items-center">
                      Séléctionnez une date sur le calendrier
                    </div>
                  </div>
                </div>
              )}
            {(getEventsIsLoading)
              && (
                <div className="absolute flex flex-col justify-center items-center text-center w-full h-full bg-white/60 top-0 z-20">
                  <i className="fas fa-spinner animate-spin mb-6 text-lg animate-pulse" />
                  <span className="animate-pulse font-bold text-balance text-xs lg:text-base">
                    Patientez, nous recherchons des dates
                  </span>
                </div>
              )}
            <div className="absolute flex flex-col justify-center items-center text-center w-full max-w-96 bg-transparent bottom-0 z-40 px-3">
              <CheckPassengerAgeAndWeight
                tandemOrderItem={tandemOrderItem}
                itemDetails={itemDetails}
                isValidAgeAndWeight={isValidAgeAndWeight}
                setIsValidAgeAndWeight={setIsValidAgeAndWeight}
                setIsValidAge={setIsValidAge}
                setIsValidWeight={setIsValidWeight}
                ageAndWeightRequirements={ageAndWeightRequirements}
              />
            </div>
          </div>
        )}
      {(showIdentityForm && !showCompletedOrderItem)
        && (
          <div className="flex flex-col justify-start items-center w-full px-3 lg:pt-6">
            {/* Age & Weight of passenger and push */}
            <PassengerIdentityForm
              cart={cart}
              setCart={setCart}
              tandemOrderItem={tandemOrderItem}
              itemDetails={itemDetails}
              eventsDetails={eventsDetails}
              nameFormat={nameFormat}
              setError={setError}
              orderIdx={orderIdx}
              setOrderIdx={setOrderIdx}
              passengerRefs={passengerRefs}
              paymentRef={paymentRef}
              setShowCompletedOrderItem={setShowCompletedOrderItem}
            />
          </div>
        )}
    </>
  );
}
