/* eslint-disable react/prop-types */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function ByAddictSection() {
  const location = useLocation();

  return (
    <div className="flex flex-col w-full">
      <div className="mb-12">
        <h3 className="text-4xl text-[#ceb071] mb-3 font-playfair drop-shadow uppercase">
          <span className="text-zinc-500 mr-3">
            Par
          </span>
          Addict
        </h3>
        <p className="">
          Concept unique et complet pour le vol libre à la Réunion.
        </p>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-8 lg:gap-9">
        <div className="relative flex justify-center items-center col-span-2 min-h-[360px] group overflow-hidden">
          <img
            alt="addictparapente"
            className="absolute object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s] cursor-pointer"
            src="/image/activities/activity-1.webp"
            loading="lazy"
          />
          <div className="absolute flex flex-col justify-end group-hover:bg-sky-900/80 h-full w-full">
            <div className="absolute bottom-3 flex items-center justify-center group-hover:-translate-y-1/2 lg:group-hover:-translate-y-32 -translate-x-1/2 left-1/2 transition duration-500
              group-hover:bg-white w-3/4 lg:w-1/2 h-42 border border-gray-300"
            >
              <Link
                className="flex flex-col justify-center items-center w-full m-3"
                to="/"
                state={{ from: location }}
              >
                <img
                  alt="logoaddictparapente"
                  className="object-center w-24 h-full"
                  src="/image/certifications/logo-qnhd.svg"
                  loading="lazy"
                />
                <p className="text-white group-hover:text-zinc-600 mt-3 uppercase text-xs lg:text-sm">
                  addictparapente.com
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className="relative flex justify-center items-center min-h-[200px] lg:min-h-[360px] group overflow-hidden">
          <img
            alt="voilerie"
            className="absolute object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s] cursor-pointer"
            src="/image/activities/activity-2.webp"
            loading="lazy"
          />
          <div className="absolute flex flex-col justify-end group-hover:bg-sky-900/80 h-full w-full">
            <div className="absolute bottom-3 flex items-center justify-center group-hover:-translate-y-1/2 lg:group-hover:-translate-y-32 -translate-x-1/2 left-1/2 transition duration-500
              group-hover:bg-white w-full p-3 lg:w-3/4 h-42 border border-gray-300"
            >
              <a
                className="flex flex-col justify-center items-center w-full m-0 lg:m-3"
                href="http://voilerie-reunion.com"
              >
                <img
                  alt="logovoilerie"
                  className="object-center w-24 h-full"
                  src="/image/addict/logo-voilerie.svg"
                  loading="lazy"
                />
                <p className="text-white group-hover:text-zinc-600 mt-3 uppercase text-xs lg:text-sm">
                  voilerie-reunion.com
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="relative flex justify-center items-center min-h-[200px] lg:min-h-[360px] group overflow-hidden">
          <img
            alt="addictburger"
            className="absolute object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s] cursor-pointer"
            src="/image/activities/activity-3.webp"
            loading="lazy"
          />
          <div className="absolute flex flex-col justify-end group-hover:bg-sky-900/80 h-full w-full">
            <div className="absolute bottom-3 flex items-center group-hover:-translate-y-1/2 lg:group-hover:-translate-y-32 -translate-x-1/2 left-1/2 transition duration-500
              group-hover:bg-white w-full p-3 lg:w-3/4 h-42 border border-gray-300"
            >
              <a
                className="flex flex-col justify-center items-center w-full m-0 lg:m-3"
                href="https://addict-burger.com/"
              >
                <img
                  alt="logorestaurant"
                  className="object-center w-24 h-full"
                  src="/image/addict/logo-restaurant.svg"
                  loading="lazy"
                />
                <p className="text-white group-hover:text-zinc-600 mt-3 uppercase text-xs lg:text-sm">
                  addict-burger.com
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="relative flex justify-center items-center min-h-[200px] lg:min-h-[360px] w-full group overflow-hidden">
          <img
            alt="airshop"
            className="absolute object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s] cursor-pointer"
            src="/image/activities/activity-4.webp"
            loading="lazy"
          />
          <div className="absolute flex flex-col justify-end group-hover:bg-sky-900/80 h-full w-full">
            <div className="absolute bottom-3 flex items-center group-hover:-translate-y-1/2 lg:group-hover:-translate-y-32 -translate-x-1/2 left-1/2 transition duration-500
              group-hover:bg-white w-full p-3 lg:w-3/4 h-42 border border-gray-300"
            >
              <a
                className="flex flex-col justify-center items-center w-full m-0 lg:m-3"
                href="https://addict.sx/"
              >
                <img
                  alt="logoairshop"
                  className="object-center w-24 h-full"
                  src="/image/addict/logo-shop.svg"
                  loading="lazy"
                />
                <p className="text-white group-hover:text-zinc-600 mt-3 uppercase text-xs lg:text-sm">
                  airshop.fr
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="relative flex justify-center items-center min-h-[200px] lg:min-h-[360px] group overflow-hidden">
          <img
            alt="formationparapente"
            className="absolute object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s] cursor-pointer"
            src="/image/activities/activity-5.webp"
            loading="lazy"
          />
          <div className="absolute flex flex-col justify-end group-hover:bg-sky-900/80 h-full w-full">
            <div className="absolute bottom-3 flex items-center group-hover:-translate-y-1/2 lg:group-hover:-translate-y-32 -translate-x-1/2 left-1/2 transition duration-500
              group-hover:bg-white w-full p-3 lg:w-3/4 h-42 border border-gray-300"
            >
              <Link
                className="flex flex-col justify-center items-center w-full m-0 lg:m-3"
                to="/"
                state={{ from: location }}
              >
                <img
                  alt="logoformationparapente"
                  className="object-center w-24 h-full"
                  src="/image/addict/logo-parapente.svg"
                  loading="lazy"
                />
                <p className="text-white group-hover:text-zinc-600 mt-3 uppercase text-xs lg:text-sm">
                  addictparapente.com
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
