/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import GetPartOfDayByQuarter from './GetPartOfDayByQuarter';

export default function OrderItemDetails({
  cart,
  setCart,
  tandemOrderItem,
  eventsDetails,
  setEventsDetails,
  setShowAgeAndWeightForm,
  showIdentityForm,
  setShowIdentityForm,
  showCompletedOrderItem,
  setShowCompletedOrderItem,
  setShowPayment,
}) {
  const eventDetails = eventsDetails?.find((j) => j.eventID === tandemOrderItem.eventID);
  return (
    <div className="flex flex-col justify-start items-stretch h-full w-96 max-w-96 uppercase font-semibold text-xs mb-3">
      {/* Button to return to age & weight form & calendar */}
      {(showIdentityForm)
        && (
          <button
            type="button"
            className="flex justify-start bg-emerald-300 hover:bg-sky-400 text-white hover:text-white shadow-lg hover:shadow-xl border px-3 py-2 rounded-lg group w-auto transition ease-in-out delay-50"
            onClick={() => {
              setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.map((j) => {
                  if (j.id === tandemOrderItem.id) {
                    return {
                      ...j,
                      eventID: null,
                    };
                  }
                  return j;
                }),
              });
              setEventsDetails(eventsDetails.filter(
                (x) => x.orderItemID === tandemOrderItem.id,
              ));
              setShowAgeAndWeightForm(true);
              setShowIdentityForm(false);
              setShowCompletedOrderItem(false);
              setShowPayment(false);
            }}
          >
            <div className="relative flex justify-center items-center hidden group-hover:block w-full">
              <i className="absolute left-0 top-1/2 -translate-y-1/2 fas fa-arrow-rotate-left bg-white rounded-full px-2 text-sky-400 text-sm mr-3" />
              Modifier
            </div>
            <div className="relative flex justify-center items-center group-hover:hidden w-full">
              <i className="absolute left-0 top-1/2 -translate-y-1/2 fas fa-check bg-white rounded-full px-2 text-emerald-300 text-sm mr-3" />
              {`Le ${new Date(eventDetails?.date).toLocaleDateString('fr-FR', {
                weekday: 'long',
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
              })} `}
              <GetPartOfDayByQuarter timeStr={eventDetails?.startTime} />
              <i className="absolute right-0 top-1/2 -translate-y-1/2 fas fa-pen-to-square px-2 text-white text-sm" />
            </div>
          </button>
        )}
      {/* Button to return to passenger identity form */}
      {(showCompletedOrderItem)
        && (
          <div className="flex flex-col gap-4">
            <button
              type="button"
              className="flex justify-start bg-emerald-300 hover:bg-sky-400 text-white hover:text-white shadow-lg hover:shadow-xl border px-3 py-2 rounded-lg group w-auto transition ease-in-out delay-50"
              onClick={() => {
                setCart({
                  ...cart,
                  tandemOrderItems: cart.tandemOrderItems.map((j) => {
                    if (j.id === tandemOrderItem.id) {
                      return {
                        ...j,
                        isSaved: false,
                      };
                    }
                    return j;
                  }),
                });
                setShowCompletedOrderItem(false);
                setShowPayment(false);
              }}
            >
              <div className="relative flex justify-center items-center hidden group-hover:block w-full">
                <i className="absolute left-0 top-1/2 -translate-y-1/2 fas fa-arrow-rotate-left bg-white rounded-full px-2 text-sky-400 text-sm mr-3" />
                Modifier
              </div>
              <div className="relative flex justify-center items-center group-hover:hidden w-full">
                <i className="absolute left-0 top-1/2 -translate-y-1/2 fas fa-check bg-white rounded-full px-2 text-emerald-300 text-sm mr-3" />
                {`Pour ${tandemOrderItem?.firstName} ${tandemOrderItem?.lastName}`}
                <i className="absolute right-0 top-1/2 -translate-y-1/2 fas fa-pen-to-square px-2 text-white text-sm" />
              </div>
            </button>
          </div>
        )}
    </div>
  );
}
