import {
  Route, Routes,
} from 'react-router-dom';
import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { loadStripe } from '@stripe/stripe-js';

import Home from './views/Home';
import BookBiplace from './views/BookBiplace';
import PaymentStatus from './views/PaymentStatus';
import ReSchedule from './views/ReSchedule';
import useDatav2 from './components/useDatav2';
import MakeAGift from './views/MakeAGift';

const stripePromise = loadStripe(`${process.env.REACT_APP_PUB_STRIPE_KEY}`);
function App() {
  const [isPILoading, setIsPILoading] = useState(false);
  const [purchaseStep, setPurchaseStep] = useState(0);
  const [eventsDetails, setEventsDetails] = useState([]);
  const [error, setError] = useState('');
  const [cart, setCart] = useState({
    tandemOrderItems: [],
    stripePaymentIntentClientSecret: '',
    stripePaymentIntentID: '',
    grandTotal: 0,
  });
  const AddOrDeleteOrderItem = (action, itemID) => {
    setIsPILoading(true);
    if (cart.stripePaymentIntentID === '' && itemID !== '') {
      fetch(`${process.env.REACT_APP_API_URL}/public/paragliding/tandem/order/create?tandem_item_id=${itemID}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((json) => {
          setIsPILoading(false);
          if (!json.error) {
            setCart(json);
          } else {
            const nerror = new Error('error');
            nerror.msg = json.error;
            throw nerror;
          }
        })
        .catch((err) => {
          setError(err);
        });
      return;
    }
    if (action === 'delete' && itemID !== '') {
      const paymentIntentID = `stripe_payment_intent_id=${cart.stripePaymentIntentID}`;
      fetch(`${process.env.REACT_APP_API_URL}/public/paragliding/tandem/order/delete_order_item?${paymentIntentID}&tandem_order_item_id=${itemID}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      })
        .then((r) => r.json())
        .then((json) => {
          setIsPILoading(false);
          if (!json.error) {
            if (json.message === 'cart deleted') {
              setCart({
                tandemOrderItems: [],
                stripePaymentIntentClientSecret: '',
                stripePaymentIntentID: '',
                grandTotal: 0,
              });
            }
            if (json.message === 'order item deleted') {
              setCart({
                ...cart,
                tandemOrderItems: cart.tandemOrderItems.filter((x) => x.id !== itemID),
                total: json.total,
                grandTotal: json.grandTotal,
              });
            }
          } else {
            const nerror = new Error('error');
            nerror.msg = json.error;
            throw nerror;
          }
        })
        .catch((err) => {
          setError(err);
        });
      return;
    }
    if (action === 'add' && itemID !== '') {
      const paymentIntentID = `stripe_payment_intent_id=${cart.stripePaymentIntentID}`;
      fetch(`${process.env.REACT_APP_API_URL}/public/paragliding/tandem/order/add_order_item?${paymentIntentID}&tandem_item_id=${itemID}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((json) => {
          setIsPILoading(false);
          if (!json.error) {
            setCart({
              ...cart,
              tandemOrderItems: [
                ...cart.tandemOrderItems,
                json.newTandemOrderItem,
              ],
              total: json.total,
              grandTotal: json.grandTotal,
            });
          } else {
            const nerror = new Error('error');
            nerror.msg = json.error;
            throw nerror;
          }
        })
        .catch((err) => {
          setError(err);
        });
      return;
    }
    setIsPILoading(false);
    setError('erreur inconnue');
  };
  const tandemItems = useDatav2('/public/paragliding/items?type=tandem');
  const courseItems = useDatav2('/public/paragliding/items?type=course');
  return (
    <Routes>
      <Route
        path="/"
        element={(
          <Home
            cart={cart}
            tandemItems={tandemItems}
            courseItems={courseItems}
            purchaseStep={purchaseStep}
            setPurchaseStep={setPurchaseStep}
            AddOrDeleteOrderItem={AddOrDeleteOrderItem}
            error={error}
            setError={setError}
            isPILoading={isPILoading}
          />
        )}
      />
      <Route
        path="/reserver/bapteme"
        element={(
          <BookBiplace
            stripePromise={stripePromise}
            cart={cart}
            setCart={setCart}
            items={tandemItems}
            isPILoading={isPILoading}
            purchaseStep={purchaseStep}
            setPurchaseStep={setPurchaseStep}
            eventsDetails={eventsDetails}
            setEventsDetails={setEventsDetails}
            error={error}
            setError={setError}
            AddOrDeleteOrderItem={AddOrDeleteOrderItem}
          />
        )}
      />
      <Route
        path="/bon_cadeau"
        element={(
          <MakeAGift
            stripePromise={stripePromise}
            cart={cart}
            setCart={setCart}
            tandemItems={tandemItems}
            courseItems={courseItems}
            isPILoading={isPILoading}
            purchaseStep={purchaseStep}
            setPurchaseStep={setPurchaseStep}
            eventsDetails={eventsDetails}
            setEventsDetails={setEventsDetails}
            error={error}
            setError={setError}
            AddOrDeleteOrderItem={AddOrDeleteOrderItem}
          />
        )}
      />
      <Route
        path="/payment_status"
        element={(
          <PaymentStatus
            stripePromise={stripePromise}
            cart={cart}
            purchaseStep={purchaseStep}
            setPurchaseStep={setPurchaseStep}
            tandemItems={tandemItems}
          />
        )}
      />
      <Route path="/reschedule/:id/:token" element={<ReSchedule />} />
      <Route
        path="*"
        element={(
          <Home
            cart={cart}
            tandemItems={tandemItems}
            courseItems={courseItems}
            purchaseStep={purchaseStep}
            setPurchaseStep={setPurchaseStep}
            AddOrDeleteOrderItem={AddOrDeleteOrderItem}
            error={error}
            setError={setError}
            isPILoading={isPILoading}
          />
        )}
      />
    </Routes>
  );
}

export default App;
